const sk = {
	"Accounts": {
		"Add": "Pridať",
		"Create": "Vytvoriť",
		"Title": "Zákaznícke účty"
	},
	"AppInfo": {
		"Company": "Xmarton",
		"CompanyFullName": "Xmarton s.r.o.",
		"Copyright": "Xmarton s.r.o.",
		"InfoEmail": "support@xmarton.com",
		"PresentationWebURL": "www.xmarton.com",
		"SupportEmail": "support@xmarton.com",
		"SupportPhoneCZ": "+420 277 001 205",
		"SupportPhoneLinkCZ": "+420277001205",
		"SupportPhoneLinkSK": "+421940504433",
		"SupportPhoneSK": "+421 940 504 433",
		"Title": "Správa služieb"
	},
	"AutoLogout": "Automaticky sme Vás odhlásili po dlhšej dobe nečinnosti.",
	"BEFORE_LOGOUT": {
		"Refresh": "Obnoviť",
		"Text": "Z dôvodu hodinovej nečinnosti budete automaticky odhlásený.",
		"TimeToLogoutMsg": "Zostávajúci čas do automatického odhlásenia:",
		"Title": "Automatické odhlásenie"
	},
	"BeforeAutologout": "Za niekoľko minút nastane automatické odhlásenie.",
	"Components": {
		"DatePicker": {
			"ChooseDay": "Vybrať deň"
		},
		"Selectbox": {
			"ChooseValue": "Vyberte hodnotu",
			"EmptyList": "prázdny zoznam",
			"Search": "Vyhľadať..."
		},
		"SelectRS": {
			"BackspaceInfo": "Backspace na zmazanie poslednej hodnoty",
			"ChoosePlaceholder": "Vyberte",
			"NoValues": "Žiadne hodnoty",
			"RemoveAllValues": "Zmazať všetky hodnoty",
			"RemoveValue": "Zmazať hodnotu",
			"WriteToSearch": "Píšte pre vyhľadávanie"
		}
	},
	"Contacts": {
		"Btn": {
			"Close": "Zavrieť"
		},
		"RegularHours": "Sme Vám k dispozícii v pracovných dňoch od 9:00 do 17:30.",
		"Support": "Technická Podpora",
		"Title": "Kontakty"
	},
	"Country": {
		"CzechRepublic": "Česká republika",
		"Slovakia": "Slovensko"
	},
	"FORGOTTEN_PASSWORD": {
		"Btn": {
			"BackToLogin": "Späť na prihlásenie",
			"Submit": "Odoslať",
			"TryAgain": "Skúsiť znovu"
		},
		"Cancel": {
			"Processing": "Rušíme požiadavku…",
			"ResultError": "Požiadavku sa nepodarilo zrušiť.",
			"ResultOK": "Požiadavku sme zrušili.",
			"Title": "Zrušenie požiadavku"
		},
		"Confirm": {
			"NewPassword": "Nové heslo",
			"NewPasswordLegend": "Heslo musí obsahovať veľké písmeno, malé písmeno a číslicu. Minimálna dĺžka musí byť 8 znakov. Povolené špeciálne znaky sú: & $ @ ! ? % * - _ , . ",
			"NewPasswordWarning": "Heslo neodpovedá požadovanému formátu.",
			"PasswordCheck": "Potvrdenie hesla",
			"PasswordCheckDifferentWarning": "Heslá sa nezhodujú.",
			"PasswordCheckEmptyWarning": "Heslo nesmie byť prázdne.",
			"PasswordCheckWarning": "Heslá nie sú rovnaké.",
			"Pending": "Odosielam požiadavku...",
			"ResultError": "Heslo sa nepodarilo zmeniť.",
			"ResultOK": "Heslo bolo úspešne vytvorené. Prihláste sa prosím pomocou nového hesla.",
			"Title": "Vytvorenie nového hesla"
		},
		"Request": {
			"Email": "Prihlasovací e-mail",
			"EmailWarning": "Nezadali ste e-mail v správnom formáte.",
			"Legend": "Na nižšie zadaný e-mail Vám odošleme odkaz na vytvorenie nového hesla.",
			"Pending": "Odosielam požiadavku...",
			"ResultError": "Požiadavku na vytvorenie nového hesla sa nepodarilo odoslať.",
			"ResultOK": "Požiadavka na vytvorenie nového hesla bola prijatá.",
			"ResultOK_secondPart": "Vytvorenie nového hesla dokončíte pomocou odkazu v e-maily, ktorý Vám bol v tejto chvíli odoslaný.",
			"Title": "Zabudnuté heslo"
		}
	},
	"ItemRequiredError": "Položka nesmie byť prázdna!",
	"LanguageByShortcut": {
		"cs": "Čeština",
		"en": "English",
		"sk": "Slovenčina"
	},
	"LOGIN": {
		"Btn": {
			"ForgottenPassword": "Zabudli ste heslo?",
			"Logging": "Prihlasujem",
			"Login": "Prihlásiť sa",
			"Registration": "Registrácia",
			"Remember": "Zapamätať e-mail"
		},
		"ChangeLanguageTitle": "Zmena jazyka",
		"Errors": {
			"InvalidGrant": "Neplatný e-mail alebo heslo.",
			"UnableLogin": "Nepodarilo sa Vás prihlásiť."
		},
		"Form": {
			"Password": "Heslo",
			"PasswordRequiredWarning": "Heslo nesmie byť prázdne!",
			"PermanentLogin": "Trvalé prihlásenie",
			"Username": "E-mail",
			"UsernameInvalidWarning": "Prihlasovací e-mail nie je platný!",
			"UsernameRequiredWarning": "Prihlasovací e-mail nesmie byť prázdny!"
		},
		"Title": "Xmarton Správa služieb"
	},
	"Menu": {
		"CarPortal": "Portál CAR",
		"Contacts": "Kontakty",
		"FleetPortal": "Portál FLEET",
		"Logout": "Odhlásiť"
	},
	"NewAccount": {
		"BillingInformations": "Fakturačné údaje",
		"Btn": {
			"Create": "Vytvoriť"
		},
		"Field": {
			"City": "Mesto",
			"Country": "Štát",
			"Name": {
				"Company": "Názov firmy / živnostníka",
				"Personal": "Meno a priezvisko"
			},
			"PostCode": "PSČ",
			"Street": "Ulica a č.p.",
			"Tin": "IČO",
			"VatPayer": "Platca DPH",
			"Vtin": "IČO DPH"
		},
		"Msg": {
			"CreateCarSuccess": "Zákaznícky účet do CAR portálu bol úspešne vytvorený. ",
			"CreateFleetSuccess": "Zákaznícky účet do FLEET portálu bol úspešne vytvorený. ",
			"Tooltip": "Zákaznícky účet na súkromnú osobu už máte vytvorený. "
		},
		"Title": "Vytvorenie zákazníckeho účtu",
		"Type": {
			"Company": "Firma alebo živnostník - portál FLEET",
			"Personal": "Súkromná osoba - portál CAR"
		},
		"TypePartTitle": "Na koho budú vedené služby"
	},
	"Options": {
		"No": "Nie",
		"Yes": "Áno"
	},
	"PartnerSection": {
		"Calc": {
			"Btn": {
				"Calc": "Spočítať",
				"Reset": "Reset"
			},
			"ChosenDuration": "Dĺžka zmluvy / záväzku",
			"ChosenInstallation": "Zahrnutá inštalácia jednotky",
			"ChosenLock": "Zahrnuté prepojenie na zámok",
			"ChosenProvision": "Výška obchodnej provízie",
			"ChosenRemoteStart": "Zahrnutý vzdialený štart",
			"ChosenRfid": "Zahrnutá čítačka čipov",
			"ChosenRtSwitch": "Zahrnutý prepínač jazdy",
			"ChosenSiren": "Zahrnutá siréna pre alarm",
			"ChosenSpeedCheck": "Zahrnuté akustické upozornenie",
			"ChosenStartStop": "Zahrnuté vypnutie start-stop",
			"ChosenUnit": "Varianta jednotky",
			"CommonOptions": {
				"No": "NIE",
				"Yes": "ÁNO"
			},
			"Countries": {
				"CR": {
					"Currency": "CZK",
					"Label": "Česká republika - CZK"
				},
				"SK": {
					"Currency": "EUR",
					"Label": "Slovenská Republika - EUR"
				}
			},
			"Country": "Krajina a mena predajcu / ponuky",
			"Duration": "Dĺžka uzatváranej zmluvy / záväzku",
			"Instal": {
				"YesLuxury": "ÁNO - luxusné vozidlo",
				"YesStandard": "ÁNO - štandardné vozidlo"
			},
			"Installation": "Zahrnúť inštaláciu do prenájmu",
			"Lock": "Prepojenie na vnútorný zámok vozidla",
			"LockTooltip": "Zamknutie vozidla s naštartovaným motorom a zapnutou klimatizáciou",
			"MonthPayment": "Mesačná splátka bez DPH",
			"MonthPaymentDph": "Mesačná splátka s DPH",
			"Provision": {
				"After": "Cenníková provízia je",
				"Label": "Obchodná provízia",
				"Tooltip": "Výška obchodnej provízie môže byť zmenená na základe potreby"
			},
			"RemoteStart": "Vzdialený štart vozidla z telefónu",
			"Rfid": "Doplniť čítačku čipov do prenájmu",
			"Rfids": {
				"YesButton": "ÁNO - iButton reader",
				"YesRfid": "ÁNO - RFID reader"
			},
			"RouteTypeSwitch": "Prepínač súkromná/služobná jazda",
			"Siren": "Siréna pre alarm a lokalizáciu",
			"SirenTooltip": "Akustická siréna pre alarm a akustickú lokalizáciu vozidla",
			"SpeedCheck": "Akustické upozornenie vo vozidle",
			"SpeedCheckTooltip": "Akustické upozornenie v kabíne vozidla pri prekročení rýchlosti, konfigurovateľné podľa požiadavky",
			"StartStopOff": "Automatické vypnutie start-stop",
			"StartStopOffTooltip": "Automatické vypnutie start-stop po rozjazde vozidla",
			"Title": "Obchodná kalkulačka",
			"UnitType": "Varianta ponúkanej jednotky",
			"Years": {
				"1": "1 rok",
				"2": "2 roky",
				"3": "3 roky",
				"4": "4 roky",
				"5": "5 rokov"
			}
		},
		"Title": "Partnerská sekcia"
	},
	"REGISTRATION": {
		"Btn": {
			"BackToLogin": "Späť na prihlásenie",
			"Register": "Registrovať sa"
		},
		"Cancel": {
			"Processing": "Rušíme požiadavku…",
			"ResultError": "Požiadavku sa nepodarilo zrušiť.",
			"ResultOK": "Požiadavka bola úspešne zrušená.",
			"Title": "Zrušenie požiadavky"
		},
		"Confirm": {
			"Processing": "Overujeme registráciu...",
			"ResultError": "Váš účet sa nám nepodarilo aktivovať. Skúste to prosím znovu.",
			"ResultOK": "Užívateľský účet bol úspešne aktivovaný, teraz sa môžete prihlásiť do mobilnej aplikácie Xmarton.",
			"Title": "Aktivácia prístupu",
			"Btn": "Aktivovať",
			"PasswordLegend": "Heslo musí obsahovať veľké písmeno, malé písmeno a číslicu. Minimálna dĺžka musí byť 8 znakov. Povolené špeciálne znaky sú: & $ @ ! ? % * - _ , ."
		},
		"Field": {
			"AgreeWithTerms": {
				"Agree": "Súhlasím s",
				"Terms": "Obchodnými podmienkami"
			},
			"Country": "Krajina",
			"Email": "Prihlasovací e-mail",
			"Firstname": "Meno ",
			"Lastname": "Priezvisko",
			"Password": "Heslo",
			"PasswordConfirm": "Potvrdenie hesla",
			"AgreeWithZou": {
				"Agree": "Súhlasím so",
				"Terms": "Spracovaním osobných údajov"
			}
		},
		"Msg": {
			"ConfirmPwdMismatchError": "Heslá sa nezhodujú.",
			"EmailFormatError": "E-mail nie je v správnom formáte.",
			"MinMaxNameError": "Minimálna dĺžka je 3 a maximálna dĺžka je 50 znakov.",
			"PwdFormatError": "Heslo neodpovedá požadovanému formátu.",
			"RegistrationSuccess": "Registrácia prebehla úspešne.",
			"SuccessInfo": "Pre dokončenie registrácie prosím kliknite na odkaz v e-maile, ktorý Vám bol teraz odoslaný.",
			"SuccessResult": "Požiadavka na vytvorenie užívateľského účtu bola prijatá."
		},
		"Title": "Registrácia"
	},
	"ServerError": "Chyba spojenia, skúste to prosím za chvíľu znovu.",
	"ServerError401": "Prihlásenie vypršalo. Prihláste sa znovu prosím.",
	"ServerError403": "Nemáte dostatočné oprávnenia. Kontaktujte prosím správcu užívateľov.",
	"ServerError500": "Požiadavka nemôže byť vybavená. Skúste to prosím za chvíľu znovu alebo kontaktujte technickú podporu.",
	"Services": {
		"Btn": {
			"Add": "Pridať"
		},
		"Modal": {
			"Btn": {
				"Cancel": "Zrušiť",
				"Save": "Uložiť"
			},
			"Items": {
				"ActivationCode": "Aktivačný kód",
				"Odometer": {
					"Label": "Stav tachometra",
					"Unit": "km"
				},
				"Rn": "EČV (evidenčné číslo vozidla)",
				"SerialNumber": "Sériové číslo",
				"VendorModel": "Výrobca a model vozidla"
			},
			"Msg": {
				"SaveSuccess": "Jednotka bola úspešne pridaná.",
				"SerialTooltip": "Určené pre jednotky Xmarton Basic so samoinštalačným balíčkom."
			},
			"Title": "Pridanie jednotky Xmarton"
		},
		"Table": {
			"Col": {
				"Activation": "Aktivácia ",
				"Box": "Jednotka Xmarton",
				"Communication": "Komunikácia",
				"Rn": "EVČ",
				"State": "Stav služby",
				"Vehicle": "Vozidlo"
			},
			"NotPaidFrom": "Nezaplatená od",
			"PaidTo": "Predplatená do"
		},
		"Title": "Prehľad jednotiek a služieb"
	},
	"TimeToAutologout": "Zostávajúci čas do automatického odhlásenia",
	"Currency": {
		"CZK": "Kč",
		"EUR": "€"
	},
	"Language": {
		"Czech": "Česky",
		"English": "English",
		"Slovak": "Slovenčina"
	},
	"ServerError404": "Požiadavka nemôže byť vybavená. Skúste to prosím za chvíľu znovu alebo kontaktujte technickú podporu.",
	"ServerError503": "Požiadavka nemôže byť vybavená. Skúste to prosím za chvíľu znovu alebo kontaktujte technickú podporu."
};

export default sk;

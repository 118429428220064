import React from 'react';
import Bowser from 'bowser';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import { ConnectedRouter } from 'connected-react-router'
import _ from 'lodash';
// import 'babel-polyfill';

import createStore from './redux/createStore';
import * as serviceWorker from './serviceWorker';
import App from './containers/App/App';
import {filterNumber, isNumber, isNotNumber, getRandomInt} from './utils/numberFormat';

const browser = Bowser.getParser(window.navigator.userAgent);

// Store and browser history
let history = createBrowserHistory();
const store = createStore(history);


//nahrani objektu pro lokalizace pokud nema prohlizec vlastni (bez toho APPka vubec nebezi)
//10.6.2016 - defaultne nefunguje v IE10 a Safari 9.1.1
if (!global.Intl) {
	require('intl');
	require('intl/locale-data/jsonp/en.js');
	require('intl/locale-data/jsonp/cs.js');
	require('intl/locale-data/jsonp/sk.js');
}
//------------------

window.renderApp = () => {
	ReactDOM.render(<Provider store={store}>
		<ConnectedRouter history={history}>
			<App/>
		</ConnectedRouter>
	</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
};


/**
 bowser.<flag> legend:
 webkit - Chrome 0-27, Android <4.4, iOs, BB, etc.
 blink - Chrome >=28, Android >=4.4, Opera, etc.
 gecko - Firefox, etc.
 msie - IE <= 11
 msedge - IE > 11
 */
window.runApp = () => {
	var validBrowser = true;

	// IE <= 10
	if (browser.getBrowserName().toLowerCase() === 'msie' && browser.getBrowserVersion() <= 9) {
		validBrowser = false;
	}

	if(validBrowser) {
		if (window.renderApp && typeof window.renderApp == 'function') {
			window.renderApp();
		}
	}
	else {
		document.getElementById('app').style.display = 'none';
		document.getElementById('unsupported-browser-message-container').style.display = 'block';
	}
};


if(!window.localStorage){
	window.localStorage = {};
}

if(!window.localStorage.getItem){
	window.localStorage.getItem = () => {
	// window.localStorage.getItem = (key) => {
		return undefined;
	}
}

if(!window.localStorage.setItem){
	window.localStorage.setItem = () => {
	// window.localStorage.setItem = (key, value) => {
		return undefined;
	}
}

if(!window.localStorage.removeItem){
	window.localStorage.removeItem = () => {
	// window.localStorage.removeItem = (key) => {
		return undefined;
	}
}


if(!window.sessionStorage){
	window.sessionStorage = {};
}

if(!window.sessionStorage.getItem){
	// window.sessionStorage.getItem = (key) => {
	window.sessionStorage.getItem = () => {
		return undefined;
	}
}

if(!window.sessionStorage.setItem){
	window.sessionStorage.setItem = () => {
	// window.sessionStorage.setItem = (key, value) => {
		return undefined;
	}
}

if(!window.sessionStorage.removeItem){
	window.sessionStorage.removeItem = () => {
	// window.sessionStorage.removeItem = (key) => {
		return undefined;
	}
}

window.filterNumber = filterNumber;
window.isNumber = isNumber;
window.isNotNumber = isNotNumber;
window.getRandomInt = getRandomInt;


window.Number.prototype.leadingZero = function(){
	return ("00" + this.valueOf()).slice (-2);
};

window.notNullNorUndefined = window.nnnu = (val)=>{
	if(val === undefined || val === null){
		return false;
	}

	return true;
};

window.hasClass = (classesString, classToFind)=> {
	classesString = classesString.replace(/\s{2,100}/g, ' ');
	classesString = classesString.trim();

	let classes = [];

	if (classesString.length){
		classes = classesString.split(' ');
	}

	classes = classes.filter((className)=>{
		if(className === classToFind){
			return true;
		}
		return false;
	});

	if(classes.length > 0){
		return true;
	}

	return false;
};

window.addClass = (classesString, classToAdd)=> {
	classesString = classesString.replace(/\s{2,100}/g, ' ');
	classesString = classesString.trim();

	let classes = [];

	if (classesString.length){
		classes = classesString.split(' ');
	}

	classes.push(classToAdd);

	classes = _.uniq(classes);

	return classes.join(' ');
};

window.removeClass = (classesString, classToRemove)=>{
	classesString = classesString.replace(/\s{2,100}/g, ' ');
	classesString = classesString.trim();

	let classes = [];

	if (classesString.length){
		classes = classesString.split(' ');
	}

	classes = classes.filter((className)=>{
		if(className !== classToRemove){
			return true;
		}
		return false;
	});

	classes = _.uniq(classes);

	return classes.join(' ');
};

window.Number.prototype.round10 = function(precision) {
	let number = this.valueOf();
	var factor = Math.pow(10, precision);
	var tempNumber = number * factor;
	var roundedTempNumber = Math.round(tempNumber);
	return roundedTempNumber / factor;
};

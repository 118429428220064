

import classnames from 'classnames';
import initForm from 'react_form';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import TextInput from '../../components/TextInput/TextInput';
import { handleErrorFromAjax } from '../../utils/errorHandler';
import { passwordRegexp } from '../../constants/regularExpressions';
import { ExtendedComponent } from '../../utils/reactExtension';

import '../../styles/before-login.scss';
import './registration.scss';
import './registrationConfirm.scss';


export default class RegistrationConfirm extends ExtendedComponent {
	static propTypes = {};

	static contextTypes = {
		router: PropTypes.object,
		routerLocation: PropTypes.object,
		translate: PropTypes.func.isRequired,
		pushState: PropTypes.func.isRequired,
		ajax: PropTypes.func.isRequired,
		loaderStart: PropTypes.func.isRequired,
		loaderStop: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			result: null,
			resultType: ''
		};
	}

	UNSAFE_componentWillMount() {


		let query = queryString.parse(this.props.location.search);
		if (!query.token) {
			console.log('No token.');
			return this.context.pushState('/');
		}

		this.token = query.token;
		this.pwd = query.pwd === 'true';
		console.log('RegistrationConfirm.UNSAFE_componentWillMount', { token: this.token, pwd: this.pwd });

		if (!this.pwd) {
			this.sendConfirm({ code: query.token });
		} else {
			this.prepareForm();
			this.setState({ resultType: 'pwd' });
		}
	}

	prepareForm() {
		this.form = initForm({
			setState: this.setState.bind(this),
			model: {
				password: '',
				passwordConfirm: ''
			}
		});
	}

	sendConfirm(data) {
		let _self = this;
		this.setState({ resultType: 'pending' });
		this.context.ajax('post', '/auth/register/confirm', data).then(() => {
			if (_self.mounted) {
				_self.setState({
					result: _self.context.translate('REGISTRATION.Confirm.ResultOK'),
					resultType: 'success'
				});
			}
		}, (error) => {
			let message = handleErrorFromAjax(error, _self.context.translate('REGISTRATION.Confirm.ResultError'));

			if (_self.mounted && error.status != 500) {
				_self.setState({
					result: message,
					resultType: 'error'
				});
			}
		});
	}

	isPwdMismatch() {
		return this.form.getVal('passwordConfirm') && this.form.getVal('password') != this.form.getVal('passwordConfirm');
	}

	getDataFromForm() {
		let result = {
			password: this.form.getVal('password'),
			code: this.token
		};
		return result;
	}

	confirm() {
		if (!this.form.submitted) {
			this.form.submitted = true;
			this.forceUpdate();
		}

		if (this.form.valid && !this.isPwdMismatch()) {
			let data = this.getDataFromForm();
			this.sendConfirm(data);
		}
	}

	render() {
		let error = this.state.resultType === 'error';
		let success = this.state.resultType === 'success';
		let pending = this.state.resultType === 'pending';
		let pwd = this.state.resultType === 'pwd';
		let res = success || error;
		let icon = error ? 'fa-times' : 'fa-check';

		return (
			<div className="dialog-container registration-confirm">
				<div className="dialog">
					<div className="title">{this.context.translate('REGISTRATION.Confirm.Title')}</div>
					{pending &&
						<div className="pending-row">
							<div className="loader" />
							<div className="text">{this.context.translate('REGISTRATION.Confirm.Processing')}</div>
						</div>
					}
					{res &&
						<div className="status">
							<div className={classnames("icon", { bad: error })}>
								<i className={classnames("fa", icon)} />
							</div>
							<div className="message">{this.state.result}</div>
						</div>
					}
					{pwd &&
						<div className="label-value-form">
							<div className="row">
								<div className="col">
									<div className="label">{this.context.translate("REGISTRATION.Field.Password")}</div>
									<div className="value">
										<TextInput
											name="password"
											type="password"
											ref={(self) => this.form.ref(self, 'password')}
											value={this.form.getVal('password')}
											onChange={e => this.form.setVal('password', e.target.value)}
											placeholder=" "
											errorMessage={this.form.errorMessage('password', {
												required: this.context.translate('ItemRequiredError'),
												pattern: this.context.translate('REGISTRATION.Msg.PwdFormatError')
											})}
											formSubmitted={this.form.submitted}
											required
											pattern={passwordRegexp}
											autoFocus={true}
										/>
										<div className="form-input-icon">
											<i className="fa fa-lock" />
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<div className="label">{this.context.translate("REGISTRATION.Field.PasswordConfirm")}</div>
									<div className="value">
										<TextInput
											name="passwordConfirm"
											type="password"
											ref={(self) => this.form.ref(self, 'passwordConfirm')}
											value={this.form.getVal('passwordConfirm')}
											onChange={e => this.form.setVal('passwordConfirm', e.target.value)}
											placeholder=" "
											errorMessage={this.form.error('passwordConfirm') ? this.context.translate('ItemRequiredError') : this.isPwdMismatch() ? this.context.translate('REGISTRATION.Msg.ConfirmPwdMismatchError') : ''}
											formSubmitted={this.form.submitted}
											required
										/>
										<div className="form-input-icon">
											<i className="fa fa-lock" />
										</div>
										<div className="password-legend">
											{this.context.translate('REGISTRATION.Confirm.PasswordLegend')}
										</div>
									</div>
								</div>
							</div>
							<div className="row buttons-container">
								<button
									className="button main-primary"
									onClick={() => { this.confirm() }}
								>
									{this.context.translate('REGISTRATION.Confirm.Btn')}
								</button>
							</div>
						</div>
					}
					<div className="back-link-row">
					</div>
				</div>
			</div>
		);
	}
};

const LngLocalStoreKey = 'language';

export const defaultLanguage = 'en';
export const supportedLanguages = [
    { key: 'cs', text: 'Language.Czech' },
    { key: 'en', text: 'Language.English' },
    { key: 'sk', text: 'Language.Slovak'}
];

export function detectAppLanguage() {
    let lng = defaultLanguage;
    // let lsLng, navLng;

    if (getLngFromLocalStore()) {
        // Pokud je jazyk v local store, tak se pouzije
        // lng = lsLng;
        lng = getLngFromLocalStore();
        // console.log("Use language from store.", lng);
    } else if (getLngFromNavigator()) {
        // Jinak vezmi jazyk z prohlizece
        // lng = navLng;
        lng = getLngFromNavigator();
        // console.log("Use language from navigator.", lng);
    } else {
        // console.log("Use default language.", lng);
    }

    // Pokud je jazyk podporovany, tak se pouzije
    if (supportedLanguages.find(o => o.key === lng)) {
        return lng;
    }

    return defaultLanguage;
}

export function setLngToLocalStore(lng) {
    localStorage.setItem(LngLocalStoreKey, lng);
}

export function getLngFromLocalStore() {
    return localStorage.getItem(LngLocalStoreKey);
}

export function getLngFromNavigator() {
    let lngs = getNavigatorLanguages();
    if (lngs || lngs.length) {
        let parts = lngs[0].split('-');
        if (parts && parts.length) {
            return parts[0];
        }
    }

    return undefined;
}

export function getNavigatorLanguages() {
    let found = [];

    if (typeof navigator !== 'undefined') {
        if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
            for (let i = 0; i < navigator.languages.length; i++) {
                found.push(navigator.languages[i]);
            }
        }
        if (navigator.userLanguage) {
            found.push(navigator.userLanguage);
        }
        if (navigator.language) {
            found.push(navigator.language);
        }
    }

    return found.length > 0 ? found : undefined;
}

export function getLngFromHeader() {
    // Jazyk je mozne jeste ziskat z headeru ajax requestu. Dela to napr. Facebook, ale my to asi nepotrebujeme...
    return undefined;
}

import {LOGOUT} from './authStore';

export const START_ONE = 'LOADER_MAIN_START_ONE';
export const STOP_ONE = 'LOADER_MAIN_STOP_ONE';
export const STOP_ALL = 'LOADER_MAIN_STOP_ALL';

const initialState = {
	running: false,
	number: 0,
	ids: {}
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case START_ONE:
			if(action.id) {
				let ids = {...state.ids};
				ids[action.id] = true;
				return {...state, ids: ids, number: Object.keys(ids).length, running: true};
			}

			return state;

		case STOP_ONE:
			if(action.id) {
				let ids = {...state.ids};

				if (ids[action.id]) {
					delete ids[action.id];

					if(Object.keys(ids).length > 0) {
						return {...state, ids: ids, number: Object.keys(ids).length};
					}
					else{
						return {...state, ids: {}, number: 0, running: false};
					}
				}
			}

			return state;

		case STOP_ALL:
			// return {...state, running: false, number: 0};
			return {...state, number: 0, ids: {}, running: false};

		case LOGOUT:
			return initialState;

		default:
			return state;
	}
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';

import './loader-main.scss';

class LoaderMain extends Component{
    static propTypes = {
    	running: PropTypes.bool
    };
    static contextTypes = {
    };

    render() {
	    let running = this.props.running;
        return (
	        <div className={classnames('loader-main-light', {'show': running})}>
		        <div className="loader-main-light-box">
			        <i className="fas fa-sync-alt fa-spin" />
		        </div>
	        </div>
        );
    }
};

const mapStateToProps = state => ({
	running: state.loaderMain.running
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderMain);
import {START_ONE, STOP_ONE, STOP_ALL} from '../stores/loaderMainStore';

function makeId(){
	return Date.now()+'_'+Math.random();
}

export function startOne() {
	return dispatch => {
		let id = makeId();

		dispatch({
			type: START_ONE,
			id
		});

		return id;
	};
}

export function stopOne(id) {
	return dispatch => {
		dispatch({
			type: STOP_ONE,
			id
		});
	};
}

export function stopAll() {
	return dispatch => {
		dispatch({
			type: STOP_ALL
		});
	};
}

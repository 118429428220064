import _ from 'lodash';
import i18next from 'i18next';

import csTranslation from './cs';
import enTranslation from './en';
import skTranslation from './sk';


function merge(original, extension){
	if(original && extension && typeof original == 'object' && typeof extension == 'object'){
		return _.merge(original, extension);
	}

	return original;
}

export default function init(language){
	let cs = csTranslation;
	let en = merge(JSON.parse(JSON.stringify(cs)), enTranslation);
	let sk = merge(JSON.parse(JSON.stringify(cs)), skTranslation);

	i18next.init({
		lng: language || window.language,
		resources: {
			cs: {
				translation: cs
			},
			en: {
				translation: en
			},
			sk: {
				translation: sk
			}
		}
	});

	return i18next;
}

import { LOGOUT } from './authStore';

import { detectAppLanguage, setLngToLocalStore } from '../../utils/language';
export const SET_LANGUAGE = 'global/SET_LANGUAGE';
export const SET_GOOGLE = 'global/SET_GOOGLE';
export const SET_HEADER_SIZE = 'global/SET_HEADER_SIZE';
export const SET_FOOTER_SIZE = 'global/SET_FOOTER_SIZE';
export const SHOW_SELECT_COMPANY = 'global/SHOW_SELECT_COMPANY';
export const HIDE_SELECT_COMPANY = 'global/HIDE_SELECT_COMPANY';
export const SET_DASHBOARD_LAST_ROUTE = 'global/SET_DASHBOARD_LAST_ROUTE';
export const SHOW_BEFORE_LOGOUT_DIALOG = 'global/SHOW_BEFORE_LOGOUT_DIALOG';
export const HIDE_BEFORE_LOGOUT_DIALOG = 'global/HIDE_BEFORE_LOGOUT_DIALOG';
export const SET_NAVIGATION = 'global/SET_NAVIGATION';

// const timeNow = new Date();
window.language = detectAppLanguage();

// --------------------------------------------------
const initialState = {
	language: window.language,
	google: null,
	headerSize: 0,
	footerSize: 0,
	showSelectCompany: false,
	dashboardLastRoute: '',
	showBeforeLogoutDialog: false,
	navTitle: '',
	navPrevText: '',
	navPrevLink: ''
};

export default function reducer(state = initialState, action = {}) {
	const { type } = action;

	switch (type) {

		case SET_NAVIGATION:
			return { ...state, navTitle: action.title, navPrevText: action.prevText, navPrevLink: action.prevLink };

		case SET_LANGUAGE:
			if (action.oldLanguage !== action.newLanguage) {
				window.language = action.newLanguage;
				setLngToLocalStore(action.newLanguage);
				return { ...state, language: action.newLanguage };
			}
			else {
				return state;
			}
			break;

		case SET_GOOGLE:
			return { ...state, google: { ...action.google } };
			break;

		case SET_HEADER_SIZE:
			return { ...state, headerSize: action.headerSize };
			break;

		case SET_FOOTER_SIZE:
			return { ...state, footerSize: action.footerSize };
			break;

		case SHOW_SELECT_COMPANY:
			return { ...state, showSelectCompany: true };
			break;

		case HIDE_SELECT_COMPANY:
			return { ...state, showSelectCompany: false };
			break;

		case SET_DASHBOARD_LAST_ROUTE:
			return { ...state, dashboardLastRoute: action.data };

		case LOGOUT:
			return { ...state, showSelectCompany: false, dashboardLastRoute: '' };
			break;

		case SHOW_BEFORE_LOGOUT_DIALOG:
			return { ...state, showBeforeLogoutDialog: true };
			break;

		case HIDE_BEFORE_LOGOUT_DIALOG:
			return { ...state, showBeforeLogoutDialog: false };
			break;

		default:
			return state;
	}
}

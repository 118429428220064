import i18n from '../i18n/i18n';

function translate(key){
	return i18n().t(key);
}

export function handleErrorFromAjax(error, customMessage, forceCustomMessage) {
	let message = customMessage;

	if(forceCustomMessage) {
		return customMessage;
	}

	if(error && typeof error == 'object') {
		if (error.status >= 500 && error.status <= 599) {
			message = translate('ServerError');
		}
		else if (error.data && error.data.message) {
			message = error.data.message;
		} else if (error.message) {
			message = error.message;
		}
	}
	else if(error && typeof error == 'string' && error.length){
		message = error;
	}

	return message;
}

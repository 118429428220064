import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';

import './textInput.scss';


export default class TextInput extends Component {
	static propTypes = {
		type: PropTypes.oneOf(['text', 'email', 'number', 'password', 'textarea']),
		name: PropTypes.string,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.bool
		]),
		label: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.element
		]),
		placeholder: PropTypes.string,
		autoComplete: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.string
		]),
		autoFocus: PropTypes.bool,
		disabled: PropTypes.bool,
		formSubmitted: PropTypes.bool,
		errorMessage: PropTypes.string,

		validateOn: PropTypes.oneOf(['submit', 'change', 'blur', 'now']),
		disableValidationOKicon: PropTypes.bool,
		disableValidationERRORicon: PropTypes.bool,

		addOnStart: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.element
		]),
		addOnEnd: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.element
		]),

		onChange: PropTypes.func.isRequired,
		onBlur: PropTypes.func,
		onFocus: PropTypes.func,

		className: PropTypes.string,
		okStatusIconEnabled: PropTypes.bool
	};

	static defaultProps = {
		type: 'text',
		name: '',
		value: undefined,
		label: null,
		placeholder: '',
		autoComplete: false,
		autoFocus: false,
		disabled: false,
		formSubmitted: false,
		errorMessage: '',
		validateOn: 'submit',
		disableValidationOKicon: false,
		disableValidationERRORicon: false,
		addOnStart: null,
		addOnEnd: null,
		className: '',
		okStatusIconEnabled: false,
		onChange: () => { },
		onBlur: () => { },
		onFocus: () => { }
	}

	firstFocus = false;
	firstBlur = false;

	constructor(props) {
		super(props);

		this.state = {
			labelAsPlaceholder: false,
			focused: false
		};

		this.focusInput = this.focusInput.bind(this);
		this.blurInput = this.blurInput.bind(this);
		this.textAreaAdjust = this.textAreaAdjust.bind(this);
		this.onChangeOrInput = this.onChangeOrInput.bind(this);
	}

	componentDidMount() {
		if (this.refs.text) {
			if ('oninput' in this.refs.text) {
				this.refs.text.oninput = this.onChangeOrInput;
			}
			else {
				this.refs.text.onchange = this.onChangeOrInput;
			}
		}
	}

	focusInput(e) {
		this.firstFocus = true;

		this.setState({ focused: true });

		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	}

	blurInput(e) {
		this.firstBlur = true;

		this.setState({ focused: false });

		if (this.props.value !== this.refs.text.value) {
			this.props.onChange({
				target: {
					value: this.refs.text.value
				}
			});
		}

		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	}

	textAreaAdjust() {
		let el = findDOMNode(this.refs.text);
		if (el) {
			el.style.height = "1px";
			el.style.height = `${20 + el.scrollHeight}px`;
		}
	}

	onChangeOrInput(e) {
		if (this.props.onChange) {
			this.props.onChange(e, e.target.value);
		}
	}

	forceFocus() {
		if (this.refs.text) {
			this.refs.text.focus();
			this.refs.text.setSelectionRange(50000, 50000);
		}
	}

	hasValue() {
		if (this.props.value !== undefined || this.props.value !== null) {
			if (typeof this.props.value === 'string' && !this.props.value.trim().length) {
				return false;
			}

			return true;
		}

		return false;
	}

	render() {
		// Promenna autocomplete musi byt stringovat kvuli akceptaci starsimi browsery
		let autoComplete = 'off';
		if (this.props.autoComplete === true || this.props.autoComplete === 'on') {
			autoComplete = 'on';
		}

		let value = '';
		if (this.props.value !== undefined && this.props.value !== null && this.props.value !== '') {
			value = this.props.value;
		}

		let status = '';
		if ((this.props.errorMessage && this.props.errorMessage.length) && (this.firstBlur || this.props.formSubmitted === true)) {
			status = 'error';
		}
		else if (this.props.okStatusIconEnabled && !(this.props.errorMessage && this.props.errorMessage.length) && this.hasValue()) {
			status = 'ok';
		}
		
		let inputType = 'text';
		if (this.props.type) {
			inputType = this.props.type;
		}


		let showErrorMessage = this.props.formSubmitted === true ? true : false;

		switch (this.props.validateOn) {
			case 'now':
				showErrorMessage = true;
				break;
			case 'change':
				if (this.firstFocus) {
					showErrorMessage = true;
				}
				break;
			case 'blur':
				if (!this.state.focused && this.firstBlur) {
					showErrorMessage = true;
				}
				break;
		}

		return (<div ref="component"
			className={classnames({
				'lib-form-component': true,
				'textinput-component': true,
				'ok': status == 'ok',
				'error': status == 'error',
				'disabled': this.props.disabled,
				'add-on': !!this.props.addOnStart || !!this.props.addOnEnd,
				'multiline': inputType == 'textarea',
				'add-on-end': this.props.addOnEnd,
				'focused': this.state.focused
			}, this.props.className)}
			onClick={(e) => { if (!this.state.focused && !this.props.disabled) { this.setState({ focused: true }, () => { this.refs.text.focus() }) } }}
		>
			{this.props.label &&
				<label className={classnames('form-component-label')}
					onClick={(e) => { if (!this.state.focused && !this.props.disabled) { this.setState({ focused: true }, () => { this.refs.text.focus() }) } }}
				>
					{this.props.label}
				</label>}

			<div className="form-component-content">
				{this.props.addOnStart && <div className="form-component-add-on-start">{this.props.addOnStart}</div>}

				{inputType != 'textarea' && inputType != 'password' &&
					<input
						ref="text"
						type={inputType}
						name={autoComplete == 'on' ? this.props.name : ''}
						className="form-component-control"
						autoComplete={autoComplete}
						value={value}
						onChange={() => { }}
						onFocus={this.focusInput}
						onBlur={this.blurInput}
						placeholder={!this.state.focused && this.props.placeholder ? this.props.placeholder : null}
						disabled={this.props.disabled}
						autoFocus={this.props.autoFocus}
					/>}

				{inputType == 'textarea' &&
					<textarea
						type="text"
						ref="text"
						name={autoComplete == 'on' ? this.props.name : ''}
						className="form-component-control textarea"
						autoComplete={autoComplete}
						value={value}
						onChange={() => { }}
						onFocus={this.focusInput}
						onBlur={this.blurInput}
						placeholder={!this.state.focused && !this.state.labelAsPlaceholder && this.props.placeholder ? this.props.placeholder : null}
						disabled={this.props.disabled}
						autoFocus={this.props.autoFocus}
					/>}

				{inputType == 'password' &&
					<input
						ref="text"
						type="password"
						name={autoComplete == 'on' ? this.props.name : ''}
						className="form-component-control"
						autoComplete={autoComplete}
						value={value}
						onChange={() => { }}
						onFocus={this.focusInput}
						onBlur={this.blurInput}
						placeholder={!this.state.focused && this.props.placeholder ? this.props.placeholder : null}
						disabled={this.props.disabled}
						autoFocus={this.props.autoFocus}
					/>}

				<div className="form-component-status-icon">
					{!this.props.disableValidationOKicon && inputType != 'textarea' && status == 'error' &&
						<div className="form-component-error-icon"><i className="fa fa-exclamation-triangle" /></div>}

					{!this.props.disableValidationERRORicon && inputType != 'textarea' && status == 'ok' &&
						<div className="form-component-ok-icon"><i className="fa fa-check" /></div>}
				</div>

				{this.props.addOnEnd && <div className="form-component-add-on-end">{this.props.addOnEnd}</div>}

				{this.props.disabled && <div className="disabled-overlay"></div>}
			</div>

			{showErrorMessage && this.props.errorMessage && this.props.errorMessage.length &&
				<div className="form-component-error-message">{this.props.errorMessage}</div>}
		</div>);
	}
}

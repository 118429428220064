

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import initForm from 'react_form';

import {ExtendedComponent} from '../../utils/reactExtension';
import {handleErrorFromAjax} from '../../utils/errorHandler';
import {emailRegexp} from '../../constants/regularExpressions';

import './forgottenPassword.scss';

class ForgottenPasswordRequest extends ExtendedComponent{
	static contextTypes = {
		translate: PropTypes.func.isRequired,
		pushState: PropTypes.func.isRequired,
		router: PropTypes.object,
		ajax: PropTypes.func.isRequired,
		addNotification: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			pending: false,
			responseError: {},
			result: null,
			resultType: null
		};

		this.TryAgain = this.TryAgain.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.form = initForm({
			setState: this.setState.bind(this),
			model: {
				email: '',
				recaptchaResponse: ''
			}
		});
	}

	submitForm = () => {
		let _self = this;

		if(!this.state.pending && this.form.valid) {
		// if(!this.state.pending && this.form.valid && this.refs.recaptcha) {
			_self.setState({pending: true});

			// this.refs.recaptcha._reactInternalInstance._renderedComponent._instance.execute().then((recaptchaResponse) => {
			// 	_self.form.setVal('recaptchaResponse', recaptchaResponse, false);

				_self.context.ajax('post', `/auth/reset`, this.form.model)
					.then(() => {
						_self.setState({
							pending: false,
							result: _self.context.translate('FORGOTTEN_PASSWORD.Request.ResultOK'),
							resultType: 'success'
						});

					}, (error) => {
						let message = handleErrorFromAjax(error, _self.context.translate('FORGOTTEN_PASSWORD.Request.ResultError'));

						_self.refs.recaptcha._reactInternalInstance._renderedComponent._instance.reset();

						if(error.status != 500) {
							_self.setState({
								pending: false,
								result: message,
								resultType: 'error'
							});
						}
					});
			// }, (error) => {
			// 	console.log(error);
			//
			// 	if(error == '__recaptcha_timeouted__'){
			// 		_self.context.addNotification({
			// 			severity: 4,
			// 			autoclose: true,
			// 			description: _self.context.translate('Recaptcha.RecaptchaTimeouted')
			// 		});
			// 	}
			//
			// 	_self.setState({
			// 		pending: false
			// 	});
			// });
		}
	}

	TryAgain(){
		if(this.mounted) {
			this.setState({
				pending: false,
				result: null,
				resultType: null
			})
		}
	}

	render() {
		let resultIcon = 'fas fa-check';
		let resultClass = 'result-ok';

		if(this.state.resultType == 'error'){
			resultClass = 'result-error';
			resultIcon = 'fas fa-times';
		}

		return (
			<div className="forgotten-password-request">
				<div className="form-wrapper">
					<div className="form-title">{this.context.translate('FORGOTTEN_PASSWORD.Request.Title')}</div>

					{!this.state.pending && !this.state.result && <div className="form-legend">{this.context.translate('FORGOTTEN_PASSWORD.Request.Legend')}</div>}

					<form name="forgottenPasswordRequestForm"
						  className="form"
						  ref={(_self)=>{this.form.ref(_self, this.submitForm.bind(this))}}
						  noValidate
						  formNoValidate
						  autoComplete="off"
					>

						<div className="form-row result-container">
							{this.state.pending &&
								<div className="pending">
									<div className="pending-loader" />
									<div className="pending-text">{this.context.translate('FORGOTTEN_PASSWORD.Request.Pending')}</div>
								</div>}

							{!this.state.pending && this.state.result &&
								<div className="result">
									<div className="result-part1">
										<div className={classnames('result-icon', resultClass)}>
											<i className={classnames(resultIcon)}/>
										</div>
										<div className="result-text">
											{this.state.result}
										</div>
									</div>
									{this.state.resultType === 'success' && <div className="result-part2">
										{this.context.translate('FORGOTTEN_PASSWORD.Request.ResultOK_secondPart')}
									</div>}
								</div>}
						</div>

						{!this.state.pending && !this.state.result &&
							<div className="form-row">
								<div className="form-label-wrapper">
									<label className="form-label">
										{this.context.translate('FORGOTTEN_PASSWORD.Request.Email')}
									</label>
								</div>
								<div className="form-input-wrapper form-input-wrapper-prefix">
									<div className="form-input-icon">
										<i className="fa fa-user"/>
									</div>
									<input
										type="text"
										name="email"
										className="form-input"
										ref={(_self)=>this.form.ref(_self, 'email')}
										onBlur={()=>{this.form.setVal('email', this.form.getVal('email').trim());}}
										required
										pattern={emailRegexp}
										disabled={this.state.pending}

									/>
									{this.form.submitted && this.form.error('email') && <div className="form-error">{this.context.translate('FORGOTTEN_PASSWORD.Request.EmailWarning')}</div>}
								</div>
							</div>
						}

						{/*<div className="form-row form-row-recaptcha" style={{display: (!this.state.pending && !this.state.result) ? '' : 'none'}}>*/}
						{/*	<div className="form-input-wrapper">*/}
						{/*		<Recaptcha*/}
						{/*			ref="recaptcha"*/}
						{/*			processing={this.state.processing}*/}
						{/*			onError={()=>{this.form.setVal('recaptchaResponse', '', false);}}/>*/}
						{/*	</div>*/}
						{/*</div>*/}

						{!this.state.pending && !this.state.result &&
							<div className="form-row form-row-buttons margin-top">
								<button
									type="submit"
									className="button main-primary"
									disabled={this.state.pending}
								>
									{this.context.translate('FORGOTTEN_PASSWORD.Btn.Submit')}
								</button>
							</div>}

						{!this.state.pending && this.state.result && this.state.resultType == 'error' &&
							<div className="form-row form-row-buttons">
								<button
									type="submit"
									className="button main-primary"
									onClick={()=>this.TryAgain()}
								>
									{this.context.translate('FORGOTTEN_PASSWORD.Btn.TryAgain')}
								</button>
							</div>}

						<div className="form-row form-row-links">
							<Link to="/"
								  disabled={this.state.pending}
								  className="form-link">{this.context.translate('FORGOTTEN_PASSWORD.Btn.BackToLogin')}</Link>
						</div>
					</form>

					{/*<div className="change-lang-only-footer">*/}
						{/*<ChangeLanguage />*/}
					{/*</div>*/}
				</div>

				<div className={classnames('transparent-modal', {active: this.state.pending})}></div>
			</div>
		);
	}
};

export default ForgottenPasswordRequest;

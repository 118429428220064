//http://www.xe.com/symbols.php
let options = {
	'CZK': {
		symbol: 'Kč',
		defaultDecimalCount: 2,
		preciseDecimalCount: 2
	},
	'EUR': {
		symbol: '€',
		defaultDecimalCount: 2,
		preciseDecimalCount: 3
	},
	'PLN': {
		symbol: 'zł',
		defaultDecimalCount: 2,
		preciseDecimalCount: 3
	}
};

options['CZ'] = options['CZK'];
options['PL'] = options['PLN'];
options['SK'] = options['EUR'];
options['DE'] = options['EUR'];
options['AU'] = options['EUR'];

export default options;

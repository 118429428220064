import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import {withRouter} from 'react-router';
import initForm from 'react_form';
import {parse as qsParse} from 'qs';

import {ExtendedComponent} from '../../utils/reactExtension';
import {handleErrorFromAjax} from '../../utils/errorHandler';
import {passwordRegexp} from '../../constants/regularExpressions';

import './forgottenPassword.scss';

class ForgottenPassword extends ExtendedComponent{
	static contextTypes = {
		translate: PropTypes.func.isRequired,
		router: PropTypes.object,
		routerLocation: PropTypes.object,
		pushState: PropTypes.func.isRequired,
		ajax: PropTypes.func.isRequired,
		addNotification: PropTypes.func.isRequired
	};

	hash = "";

	constructor(props) {
		super(props);

		this.state = {
			pending: false,
			result: null,
			resultType: null
		};
	}

	UNSAFE_componentWillMount() {
		const params = qsParse(this.props.location.search.substring(1));

		this.hash = params.token;
		if(!this.hash) {
			// return this.context.router.push('/');
			return this.context.pushState('/');
		}

		this.form = initForm({
			setState: this.setState.bind(this),
			model: {
				recaptchaResponse: ''
			}
		});
	}

	submitForm(){
		let _self = this;

		if(!this.state.pending && this.form.valid && this.form.model.newPassword===this.form.model.passwordCheck && this.hash) {
		// if(!this.state.pending && this.form.valid && this.form.model.newPassword===this.form.model.passwordCheck && this.hash && this.refs.recaptcha) {
			_self.setState({pending: true});

			// this.refs.recaptcha._reactInternalInstance._renderedComponent._instance.execute().then((recaptchaResponse) => {
			// 	_self.form.setVal('recaptchaResponse', recaptchaResponse, false);

				_self.context.ajax('post', `/auth/reset/confirm`, {
					key: this.hash,
					// token: this.hash,
					password: this.form.model.newPassword,
					// recaptchaResponse: recaptchaResponse
				})
					.then(() => {
						_self.setState({
							pending: false,
							result: _self.context.translate('FORGOTTEN_PASSWORD.Confirm.ResultOK'),
							resultType: 'success'
						});
					}, (error) => {
						let message = handleErrorFromAjax(error, _self.context.translate('FORGOTTEN_PASSWORD.Confirm.ResultError'));

						_self.refs.recaptcha._reactInternalInstance._renderedComponent._instance.reset();

						if(error.status != 500) {
							_self.setState({
								pending: false,
								result: message,
								resultType: 'error'
							});
						}
					});
			// }, (error) => {
			// 	console.log(error);
			//
			// 	if(error == '__recaptcha_timeouted__'){
			// 		_self.context.addNotification({
			// 			severity: 4,
			// 			autoclose: true,
			// 			description: _self.context.translate('Recaptcha.RecaptchaTimeouted')
			// 		});
			// 	}
			//
			// 	_self.setState({
			// 		pending: false
			// 	});
			// });
		}
	}

	tryAgain(){
		if(this.mounted) {
			this.setState({
				pending: false,
				result: null,
				resultType: null
			})
		}
	}

	render() {
		const errors = {
			newPassword: '',
			passwordCheck: ''
		};

		if (this.form.error('newPassword')) {
			errors.newPassword = this.context.translate('FORGOTTEN_PASSWORD.Confirm.NewPasswordWarning');
		}

		if (this.form.error('passwordCheck', 'required')) {
			errors.passwordCheck = this.context.translate('FORGOTTEN_PASSWORD.Confirm.PasswordCheckEmptyWarning');
		}
		else if (this.form.getVal('passwordCheck') && this.form.getVal('newPassword') != this.form.getVal('passwordCheck')) {
			errors.passwordCheck = this.context.translate('FORGOTTEN_PASSWORD.Confirm.PasswordCheckDifferentWarning');
		}

		let resultIcon = 'fas fa-check';
		let resultClass = 'result-ok';

		if(this.state.resultType == 'error'){
			resultClass = 'result-error';
			resultIcon = 'fas fa-times';
		}

		return (
			<div className="forgotten-password-confirm">
				<div className="form-wrapper">
					<div className="form-title">{this.context.translate('FORGOTTEN_PASSWORD.Confirm.Title')}</div>

					<form name="forgottenPasswordForm"
						  className="form-horizontal forgotten-password-form"
						  ref={(self)=>{this.form.ref(self, this.submitForm.bind(this))}}
						  noValidate
						  formNoValidate
						  autoComplete="off"
					>

						<div className="form-row result-container">
							{this.state.pending &&
							<div className="pending">
								<div className="pending-loader" />
								<div className="pending-text">{this.context.translate('FORGOTTEN_PASSWORD.Confirm.Pending')}</div>
							</div>}

							{!this.state.pending && this.state.result &&
							<div className="result">
								<div className="result-part1">
									<div className={classnames('result-icon', resultClass)}>
										<i className={classnames(resultIcon)}/>
									</div>
									<div className="result-text">
										{this.state.result}
									</div>
								</div>
							</div>}
						</div>

						{!this.state.pending && !this.state.result &&
							<div className="form-row">
								<div className="form-label-wrapper">
									<label className="form-label">{this.context.translate('FORGOTTEN_PASSWORD.Confirm.NewPassword')}</label>
								</div>
								<div className="form-input-wrapper form-input-wrapper-prefix">
									<div className="form-input-icon">
										<i className="fa fa-lock"/>
									</div>
									<input
										type="password"
										name="newPassword"
										className="form-input"
										ref={(_self)=>{this.form.ref(_self, 'newPassword');}}
										required
										pattern={passwordRegexp}
										disabled={this.state.pending}
									/>
									{this.form.submitted && this.form.error('newPassword') && <div className="form-error">{this.context.translate('FORGOTTEN_PASSWORD.Confirm.NewPasswordWarning')}</div>}
									<div className="password-legend">
										{this.context.translate('FORGOTTEN_PASSWORD.Confirm.NewPasswordLegend')}
									</div>
								</div>
							</div>}

						{!this.state.pending && !this.state.result &&
							<div className="form-row">
								<div className="form-label-wrapper">
									<label className="form-label">{this.context.translate('FORGOTTEN_PASSWORD.Confirm.PasswordCheck')}</label>
								</div>
								<div className="form-input-wrapper form-input-wrapper-prefix">
									<div className="form-input-icon">
										<i className="fa fa-lock"/>
									</div>
									<input
										type="password"
										name="passwordCheck"
										className="form-input"
										ref={(_self)=>{this.form.ref(_self, 'passwordCheck');}}
										required
										disabled={this.state.pending}
									/>
									{this.form.submitted && errors.passwordCheck && <div className="form-error">{errors.passwordCheck}</div>}
								</div>
							</div>}

						{/*<div className="form-row form-row-recaptcha" style={{display: (!this.state.pending && !this.state.result) ? '' : 'none'}}>*/}
						{/*	<div className="form-input-wrapper">*/}
						{/*		<Recaptcha*/}
						{/*			ref="recaptcha"*/}
						{/*			processing={this.state.processing}*/}
						{/*			onError={()=>{this.form.setVal('recaptchaResponse', '', false);}}/>*/}
						{/*	</div>*/}
						{/*</div>*/}

						{!this.state.pending && !this.state.result &&
						<div className="form-row form-row-buttons margin-top">
							<button
								type="submit"
								className="button main-primary"
								disabled={this.state.pending}
							>
								{this.context.translate('FORGOTTEN_PASSWORD.Btn.Submit')}
							</button>
						</div>}

						{!this.state.pending && this.state.result && this.state.resultType == 'error' &&
						<div className="form-row form-row-buttons margin-top">
							<button
								type="submit"
								className="button main-primary"
								onClick={()=>this.tryAgain()}
							>
								{this.context.translate('FORGOTTEN_PASSWORD.Btn.TryAgain')}
							</button>
						</div>}

						<div className="form-row form-row-links">
							<Link to="/"
								  disabled={this.state.pending}
								  className="form-link">{this.context.translate('FORGOTTEN_PASSWORD.Btn.BackToLogin')}</Link>
						</div>
					</form>
				</div>

				{/*<div className="change-lang-only-footer">*/}
					{/*<ChangeLanguage />*/}
				{/*</div>*/}

				<div className={classnames('transparent-modal', {active: this.state.pending})}></div>
			</div>
		);
	}
};

export default withRouter(ForgottenPassword);

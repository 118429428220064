export default () => {
	if (window.sessionStorage && window.sessionStorage.removeItem) {
	}

	if(window.localStorage && window.localStorage.removeItem) {
		window.localStorage.removeItem('customer_authorizedUserHash');
		window.localStorage.removeItem('customer_lastUserInteraction');
	}
}

/*
 * Pokud neni v online sledovani zaskrtla volba, ze si uzvitel preje zapamatovat si seznam vybranych vozidel, pak
 * se seznam vybranych vozidel smaze.
 * Funkce by mela byt volana po loginu a pro jistotu i pri logoutu.
 */
export function clearOnlineWatchingStorage(idUser, idCompany){
	let userHash = btoa(idUser);
	let watchingSettings = window.localStorage.getItem('customer_online_watching');

	if(watchingSettings){
		try {
			watchingSettings = JSON.parse(watchingSettings)||{};
		}
		catch (ex){
			watchingSettings = null;
		}

		if(watchingSettings && watchingSettings[userHash] && (!watchingSettings[userHash].settings || !watchingSettings[userHash].settings.rememberCarList)){
			watchingSettings[userHash]['visibility'][idCompany] = null;
			window.localStorage.setItem('customer_online_watching', JSON.stringify(watchingSettings));
		}
	}
}

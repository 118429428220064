import _ from 'lodash';
import {LOGOUT} from './authStore';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS';

const initialState = {
	items: []
};

export default function reducer(state = initialState, action = {}) {

	switch (action.type) {

		case ADD_NOTIFICATION:
			let _itemsA = [action.item, ...state.items];

			return {...state, items: _itemsA};

		case REMOVE_NOTIFICATION:
			let itemsRM = [...state.items];

			_.remove(itemsRM, (item) => {
				return item.idItem === action.idItem;
			});

			return {...state, items: itemsRM};

		case REMOVE_ALL_NOTIFICATIONS:
			return {...state, items: []};

		case LOGOUT:
			return initialState;

		default:
			return state;
	}
}

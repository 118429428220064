import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { addLocaleData } from 'react-intl';
import { IntlProvider, updateIntl } from 'react-intl-redux';
import classnames from 'classnames';
import { Route, Redirect, withRouter, Switch } from 'react-router';

import ajax from '../../utils/ajax';
import i18n from '../../i18n/i18n';

import { addItem as addNotification, removeItem as removeNotification, removeAllItems as removeAllNotifications } from '../../redux/actions/notificationActions';
import { startOne as loaderStart, stopOne as loaderStop, stopAll as loaderStopAll } from '../../redux/actions/loaderMainActions';

import { clearOnlineWatchingStorage } from '../../utils/clearBrowserStorage';

import ForgottenPasswordRequest from '../../containers/ForgottenPassword/ForgottenPasswordRequest';
import ForgottenPasswordConfirm from '../../containers/ForgottenPassword/ForgottenPasswordConfirm';
import LoaderMain from '../../components/LoaderMain/LoaderMain';
import Notification from '../../components/Notification/Notification';
import RegistrationConfirm from '../Login/RegistrationConfirm';
import RegistrationCancel from '../Login/RegistrationCancel';


// importovani jazykovych verzi pro locale
// import enIntl from 'react-intl/locale-data/en';
// import csIntl from 'react-intl/locale-data/cs';
// import skIntl from 'react-intl/locale-data/sk';

// importovani stylu
import "../../styles/main.scss";


export const T_EDIT = 'edit';


const allowedRoutesWithoutAuthorization = [
  '/registration',
  '/registration-confirm',
  '/registration-cancel',
  '/forgotten-password',
  '/forgotten-password-request',
  '/signup-confirm'
];

class App extends Component {
  // Definovani promennych, jejich datovy typ a povinnost
  static propTypes = {
    authorized: PropTypes.bool.isRequired,
    authorizedUserHash: PropTypes.string,
    addNotification: PropTypes.func.isRequired,
    removeNotification: PropTypes.func.isRequired,
    removeAllNotifications: PropTypes.func.isRequired,
    loaderStart: PropTypes.func.isRequired,
    loaderStop: PropTypes.func.isRequired,
    loaderStopAll: PropTypes.func.isRequired,
    pushState: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    ajax: PropTypes.func.isRequired,
    headerSize: PropTypes.number.isRequired,
    idUser: PropTypes.string
  };

  // Definiovani contextove promenne z nadrazene komponenty
  static contextTypes = {
    // store: PropTypes.object.isRequired,
    router: PropTypes.object
  };

  // Definovani contextovych promennych pro zdedene komponenty
  static childContextTypes = {
    language: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    getTranslationResource: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    notifyDefaultAjaxError: PropTypes.func.isRequired,
    removeNotification: PropTypes.func.isRequired,
    removeAllNotifications: PropTypes.func.isRequired,
    pushState: PropTypes.func.isRequired,
    getRouterLocation: PropTypes.func,
    loaderStart: PropTypes.func.isRequired,
    loaderStop: PropTypes.func.isRequired,
    loaderStopAll: PropTypes.func.isRequired,
    ajax: PropTypes.func.isRequired,
    showConfirm: PropTypes.func.isRequired
  };

  // mainContentContainerMarginTop = 0;
  currentYear = new Date().getFullYear();
  checkLastUserInteractionTimestampRef = null;
  checkMultipleUserAuthRef = null;

  constructor(props) {
    super(props);

    this.state = {
      deadline: null
    };

    // Uprava URL routy, pokud obsahuje routu s mrizkou
    if (props.location.hash.indexOf('#/') == 0) {
      let pathname = props.location.hash.replace('#/', '/');
      props.pushState(pathname);
    }

    // https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
    // addLocaleData([...csIntl, ...enIntl, ...skIntl]);

    this.i18n = i18n(this.props.language);

    this.translate = this.translate.bind(this);
    this.getTranslationResource = this.getTranslationResource.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.notifyDefaultAjaxError = this.notifyDefaultAjaxError.bind(this);
    // this.setMainContentContainerMarginTop = this.setMainContentContainerMarginTop.bind(this);
  }

  UNSAFE_componentWillMount() {
    let actualRoute = this.props.location.pathname;

    // Nastaveni jazyka v Intlu
    this.props.updateIntlLanguage(this.props.language);

    this.setTitle();
  }

  componentDidMount() {
    let _self = this;

    // Kontrola, zda se uzivatel neprihlasil v jine zalozce pod jinymi prihlasovacimi udaji
    this.checkMultipleUserAuthRef = setInterval(() => {
      if (_self.props.authorized && !_self.props.checking && _self.props.authorizedUserHash) {
        let authorizedUserHash = window.localStorage.getItem("customer_authorizedUserHash");

        if (authorizedUserHash) {
          authorizedUserHash = atob(authorizedUserHash);
          authorizedUserHash = decodeURI(authorizedUserHash);
          if (authorizedUserHash && authorizedUserHash != _self.props.authorizedUserHash) {
            window.location = window.location.href;
          }
        }
        else {
          // Pokud se v jenom z tabu uzivatel odhlasi, odstrani se customer_authorizedUserHash z local storage
          // browseru. Proto je nutne vyvolat refresh stranky.
          window.location = window.location.href;
        }
      }
    }, 5 * 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.language != nextProps.language) {
      let _self = this;

      this.i18n.changeLanguage(nextProps.language);
      this.props.updateIntlLanguage(nextProps.language);

      setTimeout(() => {
        _self.props.updateIntlLanguage(nextProps.language);
      }, 50);
      this.setTitle();
    }

    if (nextProps.selectedIdCompany && !this.props.idUser && nextProps.idUser) {
      clearOnlineWatchingStorage(nextProps.idUser, nextProps.selectedIdCompany);
    }

  }

  // Nazev stranky v TABu
  setTitle() {
    let pageTitle = `${this.translate('AppInfo.Company')} - ${this.translate('AppInfo.Title')}`;
    if (pageTitle) {
      document.title = pageTitle;
    }
  }

  getChildContext() {
    return {
      language: this.props.language,
      translate: this.translate,
      addNotification: this.props.addNotification,
      notifyDefaultAjaxError: this.notifyDefaultAjaxError,
      removeNotification: this.props.removeNotification,
      removeAllNotifications: this.props.removeAllNotifications,
      loaderStart: this.props.loaderStart,
      loaderStop: this.props.loaderStop,
      loaderStopAll: this.props.loaderStopAll,
      pushState: this.props.pushState,
      getRouterLocation: () => (this.context.router.route.location.pathname),
      ajax: this.props.ajax,
      showConfirm: this.showConfirm,
      getTranslationResource: this.getTranslationResource
    };
  }

  //----------------

  notifyDefaultAjaxError(error, options) {
    // console.log('notifyDefaultAjaxError', { error, options });
    // Pro status 401, 403, 500 a 503 se zobrazuje chyba automaticky
    if (error.status != 401 && error.status != 403 && error.status != 404 && error.status != 500 && error.status != 503) {
      let args = {
        severity: 4,
        autoclose: 10,
        description: error.message,
        ...options
      };
      this.props.addNotification(args);
    }
  }

  translate(key, options) {
    if (options) {
      return this.i18n.t(key, options);
    }

    return this.i18n.t(key);
  }

  getTranslationResource(key) {
    return this.i18n.getResource(this.props.language, "translation", key);
  }

  showConfirm(properties) {
    this.refs['confirm-component'].addConfirm(properties);
  }

  isShowLoginStyle() {
    return this.props.routerLocation.location.pathname.match('/forgotten-password-request') ||
      this.props.routerLocation.location.pathname.match('/forgotten-password') ||
      this.props.routerLocation.location.pathname.match('/registration-cancel') ||
      this.props.routerLocation.location.pathname.match('/registration') ||
      this.props.routerLocation.location.pathname.match('/registration-confirm');
  }

  isResponsiveStyle() {
    return this.props.routerLocation.location.pathname.match('/login') ||
      this.props.routerLocation.location.pathname.match('/dashboard/watching') ||
      this.props.routerLocation.location.pathname.match('/dashboard/carstatus') ||
      this.props.routerLocation.location.pathname.match('/forgotten-password-request') ||
      this.props.routerLocation.location.pathname.match('/forgotten-password') ||
      this.props.routerLocation.location.pathname.match('/registration-cancel') ||
      this.props.routerLocation.location.pathname.match('/signup-confirm') ||
      this.props.routerLocation.location.pathname.match('/reports');
  }

  renderContent() {
    return (
      <div className="main-content-container default-logo"
        id="mainContentContainer"
        ref="mainContentContainer"
        style={{ marginTop: this.props.headerSize + 'px' }}
      >
        <Switch>
          <Route exact path="/signup-confirm" component={RegistrationConfirm} />
          <Route exact path="/forgotten-password-request" component={ForgottenPasswordRequest} />
          <Route exact path="/forgotten-password" component={ForgottenPasswordConfirm} />
          <Route exact path="/registration-confirm" component={RegistrationConfirm} />
          <Route exact path="/registration-cancel" component={RegistrationCancel} />
        </Switch>
      </div>
    )
  }

  render() {
    let showLogin = false;

    let headerContent = null;
    let content = this.renderContent();

    let copyright = `Copyright © 2018 - ${this.currentYear}, ${this.translate('AppInfo.Copyright')}`;

    return (
      <IntlProvider>
        <main className={
          classnames({
            'login-style': this.isShowLoginStyle() || showLogin,
            'responsive-style': this.isResponsiveStyle() || showLogin
          })
        }>
          {headerContent}

          {content}

          {(this.isShowLoginStyle() || showLogin) &&
            <div className="login-footer">{copyright}</div>
          }
          {/* 2019 - {this.currentYear} */}
          <Notification />
          <LoaderMain />
        </main>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  authorized: state.auth.authorized,
  routerLocation: state.router,
  checking: state.auth.pending,
  language: state.globalState.language,
  headerSize: state.globalState.headerSize
});

const mapDispatchToProps = {
  pushState: push,
  addNotification,
  removeNotification,
  removeAllNotifications,
  loaderStart,
  loaderStop,
  loaderStopAll,
  updateIntlLanguage: (language) => {
    return (dispatch) => {
      dispatch(updateIntl({
        locale: language
      }));
    }
  },
  ajax: (method, url, data, options) => {
    return (dispatch, getState) => {
      let language = getState().globalState.language;

      if (!options) {
        options = {};
      }

      if (!options.headers) {
        options.headers = {};
      }

      return ajax(dispatch, language, method, url, data, options);
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

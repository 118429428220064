

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import queryString from 'query-string';

import { handleErrorFromAjax } from '../../utils/errorHandler';
import { ExtendedComponent } from '../../utils/reactExtension';

import '../../styles/before-login.scss';
import './registration.scss';

export default class RegistrationCancel extends ExtendedComponent {
	static propTypes = {};

	static contextTypes = {
		router: PropTypes.object,
		routerLocation: PropTypes.object,
		translate: PropTypes.func.isRequired,
		pushState: PropTypes.func.isRequired,
		ajax: PropTypes.func.isRequired,
		loaderStart: PropTypes.func.isRequired,
		loaderStop: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			pending: false,
			result: null,
			resultType: ''
		};
	}

	UNSAFE_componentWillMount() {
		let _self = this;

		let query = queryString.parse(this.props.location.search);
		if (!query.token) {
			console.log('No token.');
			return this.context.pushState('/');
		}

		this.setState({ pending: true });

		this.context.ajax('post', '/auth/cancel', { key: query.token }).then(() => {
			if (_self.mounted) {
				_self.setState({
					pending: false,
					result: _self.context.translate('REGISTRATION.Cancel.ResultOK'),
					resultType: 'success'
				});
			}
		}, (error) => {
			let message = handleErrorFromAjax(error, _self.context.translate('REGISTRATION.Cancel.ResultError'));

			if (_self.mounted && error.status != 500) {
				_self.setState({
					pending: false,
					result: message,
					resultType: 'error'
				});
			}
		});
	}

	render() {
		let error = this.state.resultType == 'error';
		let icon = error ? 'fa-times' : 'fa-check';

		return (
			<div className="dialog-container">
				<div className="dialog">
					<div className="title">{this.context.translate('REGISTRATION.Cancel.Title')}</div>
					{this.state.pending &&
						<div className="pending-row">
							<div className="loader" />
							<div className="text">{this.context.translate('REGISTRATION.Cancel.Processing')}</div>
						</div>
					}
					{!this.state.pending &&
						<div className="status">
							<div className={classnames("icon", { bad: error })}>
								<i className={classnames("fa", icon)} />
							</div>
							<div className="message">{this.state.result}</div>
						</div>
					}
					<div className="back-link-row">
						<Link
							className="link"
							to="/"
						>{this.context.translate('REGISTRATION.Btn.BackToLogin')}</Link>
					</div>
				</div>
			</div>
		);
	}
};

const en = {
	"AutoLogout": "You have been logged out after a longer period of inactivity.",
	"BeforeAutologout": "You will be automatically logged out in a few minutes.",
	"TimeToAutologout": "Remaining time until automatic logout",
	"Accounts": {
		"Title": "Customer accounts",
		"Add": "Add",
		"Create": "Create"
	},
	"AppInfo": {
		"Company": "Xmarton",
		"CompanyFullName": "Xmarton s.r.o.",
		"Copyright": "Xmarton s.r.o.",
		"Title": "Services management",
		"InfoEmail": "support@xmarton.com",
		"PresentationWebURL": "www.xmarton.com",
		"SupportEmail": "support@xmarton.com",
		"SupportPhoneCZ": "+420 277 001 205",
		"SupportPhoneSK": "+421 940 504 433",
		"SupportPhoneLinkCZ": "00420277001205",
		"SupportPhoneLinkSK": "00421940504433"
	},
	"BEFORE_LOGOUT": {
		"Refresh": "Restore",
		"Text": "You will be automatically logged out due to an hour of inactivity.",
		"TimeToLogoutMsg": "Remaining time until automatic logout:",
		"Title": "Automatic logout"
	},
	"Components": {
		"DatePicker": {
			"ChooseDay": "Select day"
		},
		"Selectbox": {
			"EmptyList": "empty list",
			"ChooseValue": "Select a value",
			"Search": "Search..."
		},
		"SelectRS": {
			"BackspaceInfo": "Backspace to delete the last value",
			"ChoosePlaceholder": "Choose",
			"NoValues": "No values",
			"RemoveAllValues": "Delete all values",
			"RemoveValue": "Delete value",
			"WriteToSearch": "Search path"
		}
	},
	"Contacts": {
		"Title": "Contacts",
		"Btn": {
			"Close": "Close"
		},
		"RegularHours": "We are available on business days from 9 a.m. to 5:30 p.m.",
		"Support": "Technical support"
	},
	"Country": {
		"CzechRepublic": "Czech Republic",
		"Slovakia": "Slovakia"
	},
	"FORGOTTEN_PASSWORD": {
		"Btn": {
			"BackToLogin": "Back to login",
			"Submit": "Send",
			"TryAgain": "Try again"
		},
		"Cancel": {
			"Processing": "Deleting request ...",
			"ResultError": "Unable to cancel request.",
			"ResultOK": "We have cancelled the request.",
			"Title": "Cancel request"
		},
		"Confirm": {
			"NewPassword": "New password",
			"NewPasswordLegend": "The password must contain an upper case letter, a lower case letter and a digit. The minimum length is 8 characters. Permitted special characters are: & $ @ ! ? % * - _ , . ",
			"NewPasswordWarning": "The password does not match the required format.",
			"PasswordCheck": "Password confirmation",
			"PasswordCheckDifferentWarning": "Passwords do not match.",
			"PasswordCheckEmptyWarning": "You must enter the password.",
			"PasswordCheckWarning": "The passwords are not the same.",
			"Pending": "Sending request ...",
			"ResultError": "Password could not be changed.",
			"ResultOK": "Your password was successfully created. Please sign in with your new password.",
			"Title": "Create a new password"
		},
		"Request": {
			"Email": "Login email address",
			"EmailWarning": "The email address was not entered in the correct format.",
			"Legend": "We will send a link to change your password to the email address below.",
			"Pending": "Sending request ...",
			"ResultError": "Failed to send a request to create a new password.",
			"ResultOK": "We have processed your request to create a new password.",
			"ResultOK_secondPart": "Complete the creation of a new password using the link in the email.",
			"Title": "Forgotten password"
		}
	},
	"ItemRequiredError": "The field is mandatory!",
	"LanguageByShortcut": {
		"cs": "Čeština",
		"en": "English",
		"sk": "Slovenčina"
	},
	"LOGIN": {
		"Btn": {
			"ForgottenPassword": "Forget password?",
			"Logging": "I log in",
			"Login": "Log in",
			"Registration": "Registration",
			"Remember": "Remember email address"
		},
		"Errors": {
			"InvalidGrant": "Invalid email address or password.",
			"UnableLogin": "Login failed."
		},
		"Form": {
			"Password": "Password",
			"PasswordRequiredWarning": "The password cannot be left blank!",
			"PermanentLogin": "Permanent login",
			"Username": "Email address",
			"UsernameInvalidWarning": "Login email address not valid!",
			"UsernameRequiredWarning": "The login email address cannot be left blank!"
		},
		"ChangeLanguageTitle": "Change language",
		"Title": "Xmarton Services management"
	},
	"Menu": {
		"CarPortal": "Portal CAR",
		"FleetPortal": "Portal FLEET",
		"Contacts": "Contacts",
		"Logout": "Logout"
	},
	"NewAccount": {
		"Title": "Create suctomer account",
		"TypePartTitle": "Account type",
		"BillingInformations": "Billing informations",
		"Type": {
			"Company": "Company or tradesman - portal FLEET",
			"Personal": "Private person - portal CAR"
		},
		"Field": {
			"Name": {
				"Company": "Company name / tradesman",
				"Personal": "Firstname and surname"
			},
			"Tin": "Taxpayer identification number",
			"VatPayer": "Payer of vat",
			"Vtin": "Taxpayer vat identification number",
			"Street": "Street",
			"City": "City",
			"PostCode": "Post code",
			"Country": "Country"
		},
		"Btn": {
			"Create": "Create"
		},
		"Msg": {
			"CreateFleetSuccess": "Customer account to FLEET portalu was created successfuly.",
			"CreateCarSuccess": "Customer account to CAR portalu was created successfuly.",
			"Tooltip": "You have already created private customer account."
		}
	},
	"Options": {
		"Yes": "Yes",
		"No": "No"
	},
	"REGISTRATION": {
		"Btn": {
			"BackToLogin": "Back to login",
			"Register": "Register"
		},
		"Field": {
			"AgreeWithTerms": {
				"Agree": "I agree with",
				"Terms": "Bussiness terms"
			},
			"Country": "Country",
			"Email": "Login e-mail",
			"Firstname": "Firstname",
			"Lastname": "Lastname",
			"Password": "Password",
			"PasswordConfirm": "Password confirm",
			"AgreeWithZou": {
				"Agree": "I agree with",
				"Terms": "The processing of my personal data"
			}
		},
		"Msg": {
			"ConfirmPwdMismatchError": "Passwords do not match.",
			"EmailFormatError": "The email address is not in the correct format.",
			"MinMaxNameError": "The minimum length is 3 characters, the maximum length 50 characters.",
			"PwdFormatError": "The password does not match the required format.",
			"RegistrationSuccess": "Registration was successful.",
			"SuccessInfo": "For coplete registration simply click on the activation link in the email we just sent to you.",
			"SuccessResult": "Request for create user account was successfuly sent."
		},
		"Title": "Registration",
		"Confirm": {
			"Title": "Activation of account",
			"Processing": "Verifying registration ...",
			"ResultOK": "The user account has been successfully activated, you can now login to the Xmarton mobile app.",
			"ResultError": "We were unable to activate your account. Please try again.",
			"Btn": "Activate",
			"PasswordLegend": "The password must contain an upper case letter, a lower case letter and a digit. The minimum length is 8 characters. Permitted special characters are: & $ @ ! ? % * - _ , . "
		},
		"Cancel": {
			"Title": "Cancel request",
			"Processing": "Deleting request ...",
			"ResultOK": "We have cancelled the request.",
			"ResultError": "Unable to cancel request."
		}
	},
	"ServerError": "Connection error. Try again in a moment.",
	"ServerError401": "Login has expired. Please log in again.",
	"ServerError403": "You do not have sufficient permissions. Please contact the user administrator.",
	"ServerError404": "Request cannot be completed. Please try again in a moment or contact technical support.",
	"ServerError500": "Request cannot be completed. Please try again in a moment or contact technical support.",
	"ServerError503": "Request cannot be completed. Please try again in a moment or contact technical support.",
	"Services": {
		"Btn": {
			"Add": "Add"
		},
		"Modal": {
			"Title": "Přidání jednotky Xmarton",
			"Items": {
				"SerialNumber": "Serial number",
				"ActivationCode": "Activation code",
				"VendorModel": "Vendor and model of vehicle",
				"Rn": "RN (registration number)",
				"Odometer": {
					"Label": "Odometer",
					"Unit": "km"
				}
			},
			"Btn": {
				"Save": "Save",
				"Cancel": "Cancel"
			},
			"Msg": {
				"SaveSuccess": "Unit was successfuly added.",
				"SerialTooltip": "Determined for units Xmarton Basic with self-instalation package."
			}
		},
		"Table": {
			"Col": {
				"Vehicle": "Vehicle",
				"Rn": "RN",
				"Box": "Xmarton unit",
				"Activation": "Activation",
				"Communication": "Communication",
				"State": "State"
			},
			"PaidTo": "Paid to",
			"NotPaidFrom": "Not paid from"
		},
		"Title": "Overview of units and services"
	},
	"Currency": {
		"CZK": "Kč",
		"EUR": "€"
	},
	"Language": {
		"Czech": "Česky",
		"English": "English",
		"Slovak": "Slovenčina"
	}
};

export default en;

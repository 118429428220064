import currencyOptions from '../constants/currencyOptions';
import unitConvert from './unitConvert';

export function filterNumber(number){
	if(window.nnnu(number)) {
		let tmp = number.toString();
		tmp = tmp.replace(/\s/g,'');
		tmp = tmp.replace(/,/g,'.');

		let dotCounter = tmp.match(/\./g);
		dotCounter = dotCounter?dotCounter.length:0;

		if(dotCounter > 1){
			let lastIndex = tmp.lastIndexOf('.');
			tmp = tmp.substring(0, lastIndex);
		}

		if(dotCounter > 0){
			return parseFloat(tmp);
		}
		else{
			return parseInt(tmp);
		}
	}

	return number;
}

export function numberFormat(number, formatSettings, units, options) {
	let formattedNumber = '';
	let unit = '';
	let decimalCount = 2;
	let roundToDecimalCount = decimalCount;

	number = filterNumber(number);

	if(options.type && options.type != 'currency' && options.type != 'currencyPrecise') {
		let tmp = unitConvert(number, options.type, units);
		number = tmp.value;
		unit = tmp.unitLabel;
		decimalCount = tmp.decimal;
	}
	else if(options.type && (options.type == 'currency' || options.type == 'currencyPrecise')) {
		let currency = '';
		let currencySymbol = '';

		if(window.nnnu(options.currency)){
			currency = options.currency.toUpperCase();
		}

		if(currency) {
			if (currencyOptions[currency]) {
				currencySymbol = ' ' + currencyOptions[currency].symbol;
				decimalCount = currencyOptions[currency].defaultDecimalCount;
				if (options.type == 'currencyPrecise') {
					decimalCount = currencyOptions[currency].preciseDecimalCount;
				}
			}
			else{
				currencySymbol = ' ' + currency;
			}
		}
		else{
			currencySymbol = ' ???';
		}

		unit = currencySymbol;
	}

	if(window.nnnu(options.decimalCount)){
		decimalCount = options.decimalCount;
	}

	if(window.nnnu(options.roundToDecimalCount)){
		roundToDecimalCount = options.roundToDecimalCount;
	}

	if(window.nnnu(options.unit)){
		unit = options.unit;
	}

	if(options.type == 'secondsToTime') {
		formattedNumber = number;
	}
	else{
		let base = [];
		let tmp = number.round10(roundToDecimalCount).toString().split('.');

		if (tmp[0]) {
			let loopCount = Math.ceil(tmp[0].length / 3);
			for (let i = 1; i <= loopCount; i++) {
				let index = tmp[0].length - i * 3;

				if (index >= 0) {
					let substr = tmp[0].substr(index, 3);
					base.unshift(substr)
				}
				else {
					let substr = tmp[0].substr(0, 3 + index);
					base.unshift(substr);
				}
			}
		}

		if (decimalCount) {
			if (tmp[1]) {
				let decimalNumber = tmp[1] + '00000000000000';
				decimalNumber = decimalNumber.toString().substr(0, decimalCount);
				tmp[1] = decimalNumber;
			}
			else {
				tmp[1] = '00000000000000'.substr(0, decimalCount);
			}
		}

		formattedNumber = base.join(formatSettings.thousandsDelimiter);

		if (tmp[1] && decimalCount) {
			formattedNumber += formatSettings.decimalDelimiter + '' + tmp[1];
		}
	}

	if(options.returnType == 'object'){
		return {
			value: formattedNumber,
			unit: unit.trim()
		}
	}
	else{
		if(unit && !options.noUnit){
			formattedNumber += ' '+unit.trim();
		}

		return formattedNumber;
	}
}

// extendedDecimalPoint - flag, zda prijmout desetinnou carku take jako validni oddelovac desetinnych mist
export function isNumber(value, extendedDecimalPoint){
	var match = null;

	if(value === null){
		return false;
	}
	else if(value === undefined){
		return false;
	}
	else if((match = value.toString().match(/^(-){0,1}\d+$/)) && match && match.length){
		return true;
	}
	else if((match = value.toString().match(/^(-){0,1}\d+\.{1}\d+$/)) && match && match.length){
		return true;
	}

	else if(extendedDecimalPoint && (match = value.toString().match(/^(-){0,1}\d+,{1}\d+$/)) && match && match.length){
		return true;
	}

	return false;
}

export function isNotNumber(value){
	return !isNumber(value);
}

export function getRandomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

import {ADD_NOTIFICATION, REMOVE_NOTIFICATION, REMOVE_ALL_NOTIFICATIONS} from '../stores/notificationStore';

function makeId() {
	const now = Date.now();
	let text = "";
	let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	for (let i = 0; i < 5; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}

	return `${now}${text}`;
}

export function addItem(item) {
	return dispatch => {
		const idItem = makeId();

		if(item.autoclose) {
			let timeout = 15;
			if(item.autoclose && !isNaN(parseInt(item.autoclose))) {
				timeout = Math.abs(parseInt(item.autoclose));
			}

			setTimeout(() => {
				dispatch({
					type: REMOVE_NOTIFICATION,
					idItem
				});
			}, timeout * 1000);
		}


		item = {...item, idItem, created: Date.now()};

		dispatch({
			type: ADD_NOTIFICATION,
			item
		});

		return idItem;
	};
}


export function removeItem(idItem) {
	return dispatch => {
		dispatch({
			type: REMOVE_NOTIFICATION,
			idItem
		});
	};
}

export function removeAllItems() {
	return dispatch => {
		dispatch({
			type: REMOVE_ALL_NOTIFICATIONS
		});
	};
}

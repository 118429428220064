import { createStore, combineReducers, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router'

import { intlReducer } from 'react-intl-redux';
import reduxThunk from 'redux-thunk';

import auth from './stores/authStore';
import globalState from './stores/globalStore';
import notification from './stores/notificationStore';
import loaderMain from './stores/loaderMainStore';

function createStoreFnc(history) {
	const routerHistoryMiddleware = routerMiddleware(history);

	const middlewares = [reduxThunk, routerHistoryMiddleware];

	const store = createStore(
		combineReducers({
			auth,
			globalState,
			notification,
			loaderMain,
			router: connectRouter(history),
			intl: intlReducer
		}),
		{
			intl: {
				locale: 'cs'
			}
		},
		applyMiddleware(...middlewares)
	)

	return store;
}

export default createStoreFnc;

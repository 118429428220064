export const API = '/api';

// Uzivatelske/Obchodni Podminky
export const UP = {
	cs: 'https://www.xmarton.com/downloads/Xmarton_VOP_B2C_CZ_08_2019.pdf'
};

// Souhlas se zpracováním osobních údajů
export const ZOU = {
	cs: 'https://www.xmarton.com/downloads/Informace%20o%20zpracovani%20osobnich%20udaju%20pro%20zakazniky.pdf',
	sk: 'https://xmarton.sk/wp-content/uploads/2019/09/Informace-o-zpracovani-osobnich-udaju-pro-zakazniky.pdf'
};
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {connect} from 'react-redux';

import {addItem, removeItem} from '../../redux/actions/notificationActions';

import './notification.scss';

class Notification extends Component {
	static propTypes = {
		notifications: PropTypes.array.isRequired,
		removeItem: PropTypes.func.isRequired,
		addItem: PropTypes.func.isRequired
	};

	static contextTypes = {};

	render() {
		const items = this.props.notifications.map((item) => {
			const _cls = {
				'ok': item.severity == 1,
				'info': item.severity == 2,
				'warning': item.severity == 3,
				'error': item.severity == 4
			};

			return (
				<div
					key={item.idItem}
					className={classnames('notification-item', _cls)}
				>
					<div className="description">
						{item.description && item.description}
					</div>

					<div className="close-box">
						<button className="close-button" onClick={() => this.props.removeItem(item.idItem)}>
							<i className="fas fa-times" />
						</button>
					</div>
				</div>
			)
		});

		return (
			<div className="notification">
				{items}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	notifications: state.notification.items
});

const mapDispatchToProps = {
	addItem,
	removeItem
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

import react_ajax from 'react_ajax';

import { defaultLanguage } from './language';
import {API} from '../constants/URLs';
import {LOGOUT} from '../redux/stores/authStore';
import {addItem as addNotification} from '../redux/actions/notificationActions';
import i18n from '../i18n/i18n';

function translate(key){
	return i18n().t(key);
}

export function updateLastUserInteractionTimestamp(){
	window.localStorage.setItem('customer_lastUserInteraction', JSON.stringify({
		timestamp: (new Date()).toISOString()
	}));
}

// Funkce pro autorizovane AJAXove volani
export const request = (dispatch, language, method, url, data, options) => {
	language = language || defaultLanguage;

	if(!options){
		options = {};
	}

	if(!options.additionalQueryStringParams){
		options.additionalQueryStringParams = {};
	}

	options.additionalQueryStringParams.app = 'customer';

	// Nezapocitavat tento ajax dotaz jako akci provedenou uzivatelem (kvuli automatickemu odhlaseni)
	if(!options.preventRefreshUserInteraction){
		updateLastUserInteractionTimestamp();
	}

	return react_ajax({
		API,
		logoutDispatchKey: LOGOUT,
		addNotificationFnc: addNotification,
		translateFnc: translate,
		translations: {
			ServerError401: translate('ServerError401'),
			ServerError403: translate('ServerError403'),
			ServerError404: translate('ServerError404'),
			ServerError500: translate('ServerError500'),
			ServerError503: translate('ServerError503'),
			ServerError: translate('ServerError')
		},
		timeout: 60000,
		dispatchFnc: dispatch,
		language,
		method,
		url,
		data,
		options
	});
}

export default request;

import React from 'react';

// Pokud props obsahuji v nejakem atributu React element, pak se vzdy vrati true, protoze neni mozne takove
// struktury porovnat pomoci JSON.stringify
export function comparePropsAndState(nextProps, nextState){
	let containReactElement = false;

	for(var key in this.props){
		if(React.isValidElement(this.props[key])){
			containReactElement = true;
		}
	}

	for(var key2 in nextProps){
		if(React.isValidElement(nextProps[key2])){
			containReactElement = true;
		}
	}

	if(this.props.children || nextProps.children){
		containReactElement = true;
	}

	if(!containReactElement){
		if(JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {
			return false;
		}
	}

	return true;
}

export function compareProps(nextProps){
	let containReactElement = false;

	for(var key in this.props){
		if(React.isValidElement(this.props[key])){
			containReactElement = true;
		}
	}

	for(var key2 in nextProps){
		if(React.isValidElement(nextProps[key2])){
			containReactElement = true;
		}
	}

	if(this.props.children || nextProps.children){
		containReactElement = true;
	}

	if(!containReactElement) {
		if (JSON.stringify(nextProps) === JSON.stringify(this.props)) {
			return false;
		}
	}

	return true;
}

export function compareState(nextState){
	if(JSON.stringify(nextState) === JSON.stringify(this.state)) {
		return false;
	}

	return true;
}

export class ExtendedComponent extends React.Component{
	mounted = false;
	setStateCalled = false;

	constructor(){
		super(...arguments);

		this.state = {};

		let componentDidMount_backup = this.componentDidMount;
		let componentWillUnmount_backup = this.componentWillUnmount;
		let setState_backup = this.setState;

		this.setState = function(){
			this.setStateCalled = true;
			if(setState_backup) {
				setState_backup.apply(this, arguments);
			}
		}

		this.componentDidMount = function(){
			this.mounted = true;
			if(componentDidMount_backup) {
				componentDidMount_backup.call(this, arguments);
			}
		}

		this.componentWillUnmount = function(){
			this.mounted = false;
			this.setState = ()=>{};
			if(componentWillUnmount_backup) {
				componentWillUnmount_backup.call(this, arguments);
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState){
		if(this.setStateCalled){
			this.setStateCalled = false;
			return true;
		}
		else {
			return comparePropsAndState.call(this, nextProps, nextState);
		}
	}
}

const cs = {
	"AutoLogout": "Automaticky jsme Vás odhlásili po delší době nečinnosti.",
	"BeforeAutologout": "Za několik minut bude provedeno automatické odhlášení.",
	"TimeToAutologout": "Zbývající čas do automatického odhlášení",
	"Accounts": {
		"Title": "Zákaznické účty",
		"Add": "Přidat",
		"Create": "Vytvořit"
	},
	"Language": {
		"Czech": "Česky",
		"English": "English",
		"Slovak": "Slovenčina"
	},
	"AppInfo": {
		"Company": "Xmarton",
		"CompanyFullName": "Xmarton s.r.o.",
		"Copyright": "Xmarton s.r.o.",
		"Title": "Účet",
		"InfoEmail": "support@xmarton.com",
		"PresentationWebURL": "www.xmarton.com",
		"SupportEmail": "support@xmarton.com",
		"SupportPhoneCZ": "+420 277 001 205",
		"SupportPhoneSK": "+421 940 504 433",
		"SupportPhoneLinkCZ": "+420277001205",
		"SupportPhoneLinkSK": "+421940504433"
	},
	"BEFORE_LOGOUT": {
		"Title": "Automatické odhlášení",
		"Text": "Z důvodu hodinové nečinnosti budete automaticky odhlášeni.",
		"TimeToLogoutMsg": "Zbývající čas do automatického odhlášení:",
		"Refresh": "Obnovit"
	},
	"Components": {
		"DatePicker": {
			"ChooseDay": "Vybrat den"
		},
		"Selectbox": {
			"EmptyList": "prázdný seznam",
			"ChooseValue": "Vyberte hodnotu",
			"Search": "Vyhledat..."
		},
		"SelectRS": {
			"BackspaceInfo": "Backspace pro smazání poslední hodnoty",
			"ChoosePlaceholder": "Vyberte",
			"NoValues": "Žádné hodnoty",
			"RemoveAllValues": "Smazat všechny hodnoty",
			"RemoveValue": "Smazat hodnotu",
			"WriteToSearch": "Pište pro vyhledávání"
		}
	},
	"Contacts": {
		"Title": "Kontakty",
		"Btn": {
			"Close": "Zavřít"
		},
		"RegularHours": "Jsme Vám k dispozici v pracovních dnech od 9:00 do 17:30.",
		"Support": "Technická Podpora"
	},
	"Country": {
		"CzechRepublic": "Česká republika",
		"Slovakia": "Slovensko"
	},
	"FORGOTTEN_PASSWORD": {
		"Btn": {
			"BackToLogin": "Zpět na přihlášení",
			"Submit": "Odeslat",
			"TryAgain": "Zkusit znovu"
		},
		"Cancel": {
			"Processing": "Rušíme požadavek…",
			"ResultError": "Požadavek se nepodařilo zrušit.",
			"ResultOK": "Požadavek jsme zrušili.",
			"Title": "Zrušení požadavku"
		},
		"Confirm": {
			"NewPassword": "Nové heslo",
			"NewPasswordLegend": "Heslo musí obsahovat velké písmeno, malé písmeno a číslici. Minimální délka musí být 8 znaků. Povolené speciální znaky jsou: & $ @ ! ? % * - _ , . ",
			"NewPasswordWarning": "Heslo neodpovídá požadovanému formátu.",
			"PasswordCheck": "Potvrzení hesla",
			"PasswordCheckDifferentWarning": "Hesla se neshodují.",
			"PasswordCheckEmptyWarning": "Heslo nesmí být prázdné.",
			"PasswordCheckWarning": "Hesla nejsou stejná.",
			"Pending": "Odesílám požadavek...",
			"ResultError": "Heslo se nepodařilo změnit.",
			"ResultOK": "Heslo bylo úspěšně vytvořeno. Přihlaste se prosím pomocí nového hesla.",
			"Title": "Vytvoření nového hesla"
		},
		"Request": {
			"Email": "Přihlašovací e-mail",
			"EmailWarning": "Nezadali jste email ve správném formátu.",
			"Legend": "Na níže zadaný e-mail Vám zašleme odkaz pro vytvoření nového hesla.",
			"Pending": "Odesílám požadavek...",
			"ResultError": "Požadavek na vytvoření nového hesla se nepodařilo odeslat.",
			"ResultOK": "Požadavek na vytvoření nového hesla byl přijat.",
			"ResultOK_secondPart": "Vytvoření nového hesla dokončíte pomocí odkazu v e-mailu, který Vám byl nyní odeslán.",
			"Title": "Zapomenuté heslo"
		}
	},
	"ItemRequiredError": "Položka nesmí být prázdná!",
	"LanguageByShortcut": {
		"cs": "Čeština",
		"en": "English",
		"sk": "Slovenčina"
	},
	"LOGIN": {
		"Btn": {
			"ForgottenPassword": "Zapomněli jste heslo?",
			"Logging": "Přihlašuji",
			"Login": "Přihlásit se",
			"Registration": "Registrace",
			"Remember": "Zapamatovat e-mail"
		},
		"Errors": {
			"InvalidGrant": "Neplatný e-mail nebo heslo.",
			"UnableLogin": "Nepovedlo se Vás přihlásit."
		},
		"Form": {
			"Password": "Heslo",
			"PasswordRequiredWarning": "Heslo nesmí být prázdné!",
			"PermanentLogin": "Trvalé přihlášení",
			"Username": "E-mail",
			"UsernameInvalidWarning": "Přihlašovací e-mail není validní!",
			"UsernameRequiredWarning": "Přihlašovací e-mail nesmí být prázdný!"
		},
		"ChangeLanguageTitle": "Změna jazyka",
		"Title": "Xmarton Účet"
	},
	"Menu": {
		"CarPortal": "Portál CAR",
		"FleetPortal": "Portál FLEET",
		"Contacts": "Kontakty",
		"Logout": "Odhlásit"
	},
	"NewAccount": {
		"Title": "Vytvoření zákaznického účtu",
		"TypePartTitle": "Na koho budou služby vedeny",
		"BillingInformations": "Fakturační údaje",
		"Type": {
			"Company": "Firma nebo živnostník - portál FLEET",
			"Personal": "Soukromá osoba - portál CAR"
		},
		"Field": {
			"Name": {
				"Company": "Název firmy / živnostníka",
				"Personal": "Jméno a příjmení"
			},
			"Tin": "IČO",
			"VatPayer": "Plátce DPH",
			"Vtin": "DIČ",
			"VtinExtra": "IČ DIČ",
			"Street": "Ulice a č.p.",
			"City": "Město",
			"PostCode": "PSČ",
			"Country": "Stát"
		},
		"Btn": {
			"Create": "Vytvořit"
		},
		"Msg": {
			"CreateFleetSuccess": "Zákaznický účet do FLEET portálu byl úspěšně vytvořen.",
			"CreateCarSuccess": "Zákaznický účet do CAR portálu byl úspěšně vytvořen.",
			"Tooltip": "Zákaznický účet na soukromou osobu již máte vytvořen."
		}
	},
	"Options": {
		"Yes": "Ano",
		"No": "Ne"
	},
	"PartnerSection": {
		"Title": "Partnerská sekce",
		"Calc": {
			"Title": "Obchodní kalkulačka",
			"Country": "Krajina a měna prodeje / nabídky",
			"UnitType": "Varianta nabízené jednotky",
			"Duration": "Délka uzavírané smlouvy / závazku",
			"Installation": "Zahrnout instalaci do pronájmu",
			"Rfid": "Doplnit čtečku chipů do pronájmu",
			"RouteTypeSwitch": "Přepínač soukromá / služební jízda",
			"Lock": "Propojení na vnitřní zámek vozidla",
			"LockTooltip": "Zamknutí vozidla s nastartovaným motorem a zapnutou klimatizací",
			"SpeedCheck": "Akustické upozornění ve vozidle",
			"SpeedCheckTooltip": "Akustické upozornění v kabině vozidla při překročení rychlosti, konfigurovatelné  dle požadavku",
			"StartStopOff": "Automatické vypnutí start-stop",
			"StartStopOffTooltip": "Automatické vypnutí start-stop po rozjezdu vozidla",
			"Siren": "Siréna pro alarm a lokalizaci",
			"SirenTooltip": "Akustická siréna pro alarm a akustickou lokalizaci vozidla",
			"RemoteStart": "Vzdálený start vozidla z telefonu",
			"Provision": {
				"Label": "Obchodní provize",
				"After": "Ceníková provize je",
				"Tooltip": "Výška obchodní provize může být změněna na základě potřeby"
			},
			"Btn": {
				"Calc": "Spočítat",
				"Reset": "Reset"
			},
			"Countries": {
				"CR": {
					"Label": "Česká republika - CZK",
					"Currency": "CZK"
				},
				"SK": {
					"Label": "Slovenská Republika - EUR",
					"Currency": "EUR"
				}
			},
			"Years": {
				"1": "1 rok",
				"2": "2 roky",
				"3": "3 roky",
				"4": "4 roky",
				"5": "5 let"
			},
			"Instal": {
				"YesStandard": "ANO - standardní vozidlo",
				"YesLuxury": "ANO - luxusní vozidlo"
			},
			"Rfids": {
				"YesRfid": "ANO - RFID reader",
				"YesButton": "ANO - iButton reader"
			},
			"CommonOptions": {
				"No": "NE",
				"Yes": "ANO"
			},
			"ChosenUnit": "Varianta jednotky",
			"ChosenDuration": "Délka smlouvy / závazku",
			"ChosenInstallation": "Zahrnuta instalace jednotky",
			"ChosenRfid": "Zahrnuta čtečka chipů",
			"ChosenRtSwitch": "Zahrnut přepínač jízdy",
			"ChosenLock": "Zahrnuto propojení na zámek",
			"ChosenSpeedCheck": "Zahrnuto akustické upozornění",
			"ChosenStartStop": "Zahrnuto vypnutí start-stop",
			"ChosenSiren": "Zahrnuta siréna pro alarm",
			"ChosenRemoteStart": "Zahrnut vzdálený start",
			"ChosenProvision": "Výška obchodní provize",
			"MonthPayment": "Měsíční splátka bez DPH",
			"MonthPaymentDph": "Měsíční splátka s DPH"
		}
	},
	"REGISTRATION": {
		"Btn": {
			"BackToLogin": "Zpět na přihlášení",
			"Register": "Registrovat se"
		},
		"Field": {
			"AgreeWithTerms": {
				"Agree": "Souhlasím s",
				"Terms": "Obchodními podmínkami"
			},
			"Country": "Země",
			"Email": "Přihlašovací e-mail",
			"Firstname": "Jméno",
			"Lastname": "Příjmení",
			"Password": "Heslo",
			"PasswordConfirm": "Potvrzení hesla",
			"AgreeWithZou": {
				"Agree": "Souhlasím se",
				"Terms": "Zpracováním osobních údajů"
			}
		},
		"Msg": {
			"ConfirmPwdMismatchError": "Hesla se neshodují.",
			"EmailFormatError": "E-mail není ve správném formátu.",
			"MinMaxNameError": "Minimální délka je 3 a maximální délka je 50 znaků.",
			"PwdFormatError": "Heslo neodpovídá požadovanému formátu.",
			"RegistrationSuccess": "Registrace úspěšně provedena.",
			"SuccessInfo": "Pro dokončení registrace prosím klikněte na odkaz v e-mailu, který Vám byl nyní odeslán.",
			"SuccessResult": "Požadavek na vytvoření uživatelského účtu byl přijat."
		},
		"Title": "Registrace",
		"Confirm": {
			"Title": "Aktivace přístupu",
			"Processing": "Ověřujeme registraci...",
			"ResultOK": "Uživatelský účet byl úspěšně aktivován, nyní se můžete přihlásit do mobilní aplikace Xmarton.",
			"ResultError": "Váš účet se nám nepodařilo aktivovat. Zkuste to prosím znovu.",
			"Btn": "Aktivovat",
			"PasswordLegend": "Heslo musí obsahovat velké písmeno, malé písmeno a číslici. Minimální délka musí být 8 znaků. Povolené speciální znaky jsou: & $ @ ! ? % * - _ , . "
		},
		"Cancel": {
			"Title": "Zrušení požadavku",
			"Processing": "Rušíme požadavek…",
			"ResultOK": "Požadavek byl úspěšně zrušen.",
			"ResultError": "Požadavek se nepodařilo zrušit."
		}
	},
	"ServerError": "Chyba spojení, zkuste to prosím za chvíli znovu.",
	"ServerError401": "Přihlášení vypršelo. Přihlaste se prosím znovu.",
	"ServerError403": "Nemáte dostatečná oprávnění. Kontaktujte prosím správce uživatelů.",
	"ServerError404": "Požadavek nemůže být vyřízen. Zkuste to prosím za chvíli znovu, nebo kontaktujte technickou podporu.",
	"ServerError500": "Požadavek nemůže být vyřízen. Zkuste to prosím za chvíli znovu, nebo kontaktujte technickou podporu.",
	"ServerError503": "Požadavek nemůže být vyřízen. Zkuste to prosím za chvíli znovu, nebo kontaktujte technickou podporu.",
	"Services": {
		"Btn": {
			"Add": "Přidat"
		},
		"Modal": {
			"Title": "Přidání jednotky Xmarton",
			"Items": {
				"SerialNumber": "Sériové číslo",
				"ActivationCode": "Aktivační kód",
				"VendorModel": "Výrobce a model vozidla",
				"Rn": "RZ (registrační značka)",
				"Odometer": {
					"Label": "Stav tachometru",
					"Unit": "km"
				}
			},
			"Btn": {
				"Save": "Uložit",
				"Cancel": "Zrušit"
			},
			"Msg": {
				"SaveSuccess": "Jednotka byla úspěšně přidána.",
				"SerialTooltip": "Určeno pro jednotky Xmarton Basic se samoinstalačním balíčkem."
			}
		},
		"Table": {
			"Col": {
				"Vehicle": "Vozidlo",
				"Rn": "RZ",
				"Box": "Jednotka Xmarton",
				"Activation": "Aktivace",
				"Communication": "Komunikace",
				"State": "Stav služby"
			},
			"PaidTo": "Předplacena do",
			"NotPaidFrom": "Nezaplacena od"
		},
		"Title": "Přehled jednotek a služeb"
	},
	"Partner": {
		"pageNotFound": {
			"titlePlain": "Bohužel, zde není nic k vidění.",
			"youAreHere": "Nacházíte se této URL \"{url}\".",
			"link": "Vraťte se zpět na hlavní stránku!"
		},
		"detailPage": {
			"title": "Detail"
		},
		"profileLoading": "Načítání profilu...",
		"catalogCategory": {
			"product": "Produkt",
			"accessories": "Příslušenství",
			"services": "Služby"
		},
		"countryFromShort": {
			"CZ": "Česká republika",
			"SK": "Slovensko"
		},
		"currencySymbol": {
			"CZK": "Kč",
			"EUR": "€"
		},
		"languageFromShort": {
			"cs": "Česky",
			"sk": "Slovensky",
			"en": "Anglicky",
			"pl": "Polsky",
			"de": "Německy"
		},
		"role": {
			"admin": "Administrátor",
			"dealer": "Obchodník"
		},
		"menu": {
			"calc": "Kalkulátor",
			"customers": "Zákazníci",
			"businessUnits": "Obchodní partneři",
			"businessCases": "Obchodní případy",
			"offers": "Nabídky",
			"invoices": "Faktury",
			"orders": "Objednávky",
			"users": "Uživatelé",
			"dealers": "Obchodníci",
			"unresolvedPayments": "Nespárované platby",
			"repeatInvoice": "Pravidelné faktury",
			"settings": "Nastavení",
			"testing": "Testování"
		},
		"testing": {
			"menu": {
				"repeatInvoice":         "Pravidelné faktury"
			},
			"repeatInvoice": {
				"submitTest": "Spustit test",
				"stopTest": "Zastavit test",
				"startDate": "Začátek",
				"endDate": "Konec",
				"noItem": "Nejsou vytvořeny žádné testovací konfigurace.",
				"createConfigItem": "Vytvořte novou konfiguraci v sekci \"pravidelné faktury\""
			}
		},
		"settings": {
			"menu": {
				"status": "Stavy",
				"productCatalog": "Katalog",
				"pricelist": "Ceník",
				"changeCompany": "Přepnout se do jiné firmy",
				"company": "Nastavení firmy"
			},
			"category": {
				"addItem": "Přidat položku",
				"editItem": "Upravit",
				"deleteItem": "Odstranit"
			},
			"pricelist": {
				"addCountry": "Přidat zemi",
				"newPricelistCountryItemTitle": "Přidat zemi",
				"unassignPrompt": "Skutečně odstranit \"{name}\" z ceníku?",
				"assignPrompt": "Skutečně zařadit položku \"{name}\" do ceníku?",
				"legalNote": {
					"language": "Jazyk",
					"removeLanguage": "odebrat jazyk",
					"save": "Uložit změny",
					"addLanguage": "Přidat další jazyk"
				}
			},
			"pricelistItem": {
				"newPricelistItemCountryTitle": "Zařadit položku",
				"error": {
					"priceMustBeHigherThenMinPrice": "Doporučená cena musí být vyšší nebo stejná než {price}",
					"minPriceMustBeEqualOrLowerThenPrice": "Minimální cena musí být nižší nebo stejná než {price}",
					"priceMustBeEqualOrHigherThenCatalogPrice": "Cena musí být stejná nebo vyšší než {price}",
					"priceMustBeEqualOrHigherThenMinParentPrice": "Minimální cena musí být stejná nebo vyšší než {price}"
				}
			},
			"catalog": {
				"newCatalogItemTitle": "Nová položka katalogu",
				"configCatalogItemTitle": "Konfigurace položky katalogu",
				"deleteItemPrompt": "Odstranit položku \"{name}\"?",
				"category": {
					"accessories": "Příslušenství",
					"product": "Produkt",
					"services": "Služby"
				}
			}
		},
		"unresolvedPayments": {
			"assignPaymentBtn": "Spárovat platbu",
			"assignPaymentsBtn": "Spárovat platby",
			"selectInvoice": "Vyberte fakturu",
			"selectPayments": "Vyberte platbu(y)",
			"ignorePayment": "Ignorovat platbu",
			"ignorePaymentYes": "Ano, ignorovat platbu!",
			"ignorePaymentNo": "Ponechat!",
			"paymentIsIgnored": "Ignorování platby!",
			"paymentIsIgnoredText": "Platba byla ignorována!",
			"paymentIsNotIgnoredText": "Platbu se nepodařilo ignorovat!",
			"ignoreBtn": "Ignorovat",
			"amount": "Částka",
			"paymentsPairingCancelBtn": "Nepárovat",
			"paymentsPairing": "Párování plateb",
			"paymentIsPaired": "Platba byla spárována!",
			"paymentsArePaired": "Platby byly spárovány!"
		},
		"test": {
			"turnOn": "Zapnout test",
			"turnOff": "Vypnout test",
			"isTurnedOn": "Testovací",
		},
		"common": {
			"selectBusinessUnit": "Vyberte obchodního partnera",
			"notSupportedYet": "Tato funkce není zatím podporována.",
			"and": "a",
			"invoiceAddress": "Fakturační adresa",
			"start": "Začátek",
			"end": "Konec",
			"goToDetail": "Přejít na detail",
			"repeating": "Opakování",
			"test": "Test",
			"language": "Jazyk",
			"payments": "Platby",
			"listNoItems": "Žádná položka",
			"variableSymbolShortcut": "VS",
			"findCompanyNumber": "Vyhledat dle IČO",
			"openDetail": "Detail",
			"unresolvedPayments": "Nespárované platby",
			"validTo": "Platnost do",
			"goBack": "Zpět",
			"offerValidTo": "Platnost nabídky do",
			"created": "Vytvořeno",
			"minimalShort": "min.",
			"recommendedPriceShort": "dop.",
			"bankAccountDefault": "Výchozí bankovní účet",
			"bankAccounts": "Bankovní účty",
			"remove": "Odebrat",
			"save": "Uložit",
			"cancel": "Zrušit",
			"saveSuccess": "Změny byly uloženy",
			"saveError": "Změny se nepodařilo uložit",
			"closed": "Uzavřeno",
			"paid": "Zaplaceno",
			"documents": "Dokumenty",
			"invoice": "Faktura",
			"invoices": "Faktury",
			"invoiceProforma": "Proforma faktura",
			"invoiceNumber": "(Proforma) faktura č.",
			"invoiceNumberExact": "Faktura č.",
			"invoiceProformaNumberExact": "Proforma faktura č.",
			"files": "Soubory",
			"filename": "Název souboru",
			"fileCategory": "Typ soubory",
			"settings": "Nastavení",
			"size": "Velikost",
			"sale": "Sleva",
			"saleWithoutVat": "Sleva (bez DPH)",
			"opened": "Otevřeno",
			"editable": "Editace",
			"edit": "Editovat",
			"addressList": "Adresy",
			"role": "Oprávnění (role)",
			"yes": "Ano",
			"no": "Ne",
			"userList": "Uživatelé",
			"adminUser": "Administrátor",
			"canSendEmail": "Odesílání e-mailů",
			"canMakeInvoice": "Generování faktur",
			"personList": "Osoby",
			"address": {
				"plain": "Adresa",
				"main": "Sídlo",
				"mail": "Korespondenční adresa",
				"invoice": "Fakturační adresa"
			},
			"noData": "Nejsou data",
			"close": "Zavřít",
			"loadingData": "Načítám data...",
			"order": "Objednávka",
			"other": "Ostatní",
			"buyerName": "Příjemce",
			"repeatIntervalInMonth": "Opakování [měsíc]",
			"repeat": "Opakování",
			"executeInDayOfMonth": "Den v měsíci",
			"bankAccountName": "Bankovní účet",
			"dateStart": "Začátek",
			"dateEnd": "Konec",
			"pausedToDate": "Pozastaveno do",
			"repeatInvoice": "Pravidelné faktury",
			"businessCases": "Obchodní případy",
			"customer": "Zákazník",
			"uploaded": "Nahráno",
			"downloaded": "Staženo",
			"users": "Uživatelé",
			"addresses": "Adresy",
			"customers": "Zákazníci",
			"nInvoices": "Faktur",
			"orders": "Objednávky",
			"offers": "Nabídky",
			"persons": "Osoby",
			"dealers": "Obchodníci",
			"pricelist": "Ceník",
			"basicInfo": "Základní informace",
			"itemNumber": "Kód položky",
			"idUnit": "Měrná jednotka",
			"contacts": "Kontakty",
			"name": "Název",
			"nameTranslate": {
				"cs": "Název (česky)",
				"sk": "Název (slovensky)",
				"en": "Název (anglicky)",
				"de": "Název (německy)",
				"pl": "Název (polsky)",
				"hu": "Název (maďarsky)",
				"el": "Název (řecky)"
			},
			"bankAccountPrefix": "Předčíslí",
			"accountNumber": "Číslo účtu",
			"accountName": "Název účtu",
			"bankAccount": "Číslo účtu",
			"swiftBic": "SWIFT/BIC",
			"iban": "IBAN",
			"bankName": "Název banky",
			"token": "Token",
			"bankCode": "Kód banky",
			"invoiceDefaultDue": "Splatnost faktur [dny]",
			"companyType": "Typ zákazníka",
			"companyTypeIsCompany": "Firma / podnikatel",
			"companyTypeIsNotCompany": "Fyzická osoba",
			"nameFallback": "Název (fallback)",
			"customerInfo": "Informace o zákazníkovi",
			"companyInfo": "Informace o společnosti",
			"isCompanyOrBusinessman": "Firma / podnikatel",
			"carAccount": "Účet",
			"companyNumber": "IČO",
			"countOffers": "Nabídky",
			"identificationNumberBusinessUnit": "Kód obchodního partnera",
			"info": "Info",
			"youAreNotPrimaryBusinessUnit": "Nejste primárním obchodníkem tohoto zákazníka",
			"notSet": "Nenastaven",
			"identificationNumberCustomer": "Kód zákazníka",
			"identificationNumberBusinessCase": "Kód obchodního případu",
			"identificationNumberOffer": "Kód nabídky",
			"vatNumber": "DIČ",
			"transferredTaxLiability": "Přenesená daňová povinnost",
			"allowSendingEmails": "Povolit odesílání e-mailů",
			"vatNumberExtra": "IČ DPH",
			"lastChange": "Poslední změna",
			"price": "Cena",
			"priceWithoutVat": "Cena (bez DPH)",
			"minPrice": "Minimální cena",
			"minPriceShort": "Min. cena",
			"priceRecommended": "Doporučená cena",
			"vatRate": "Sazba DPH",
			"vatRateStandard": "Základní sazba DPH",
			"vatRateReduced": "Snížená sazba DPH",
			"withoutVat": "bez DPH",
			"withVat": "s DPH",
			"vatRateReduced2": "Snížená sazba DPH 2",
			"status": "Stav",
			"sentDate": "Odesláno",
			"customerCount": "Zákazníci",
			"dealerCount": "Obchodníci",
			"lastActivity": "Poslední aktivita",
			"note": "Poznámka",
			"dealer": "Obchodník",
			"contactPerson": "Kontaktní osoba",
			"vat": "DPH",
			"vatPayer": "Plátce DPH",
			"customerType": "Produkt",
			"userAccount": "Uživatelský účet",
			"admin": "Admin",
			"total": "Celkem",
			"position": "Pozice",
			"businessUnit": "Obchodní partner",
			"businessUnitMain": "Obchodní partner (získatel)",
			"businessCase": "Obchodní případ",
			"offer": "Nabídka",
			"workingEmail": "Sběrný email",
			"offerNumber": "Nabídka č.",
			"subscriber": "Odběratel",
			"supplier": "Dodavatel",
			"firstName": "Jméno a příjmení",
			"lastName": "Příjmení",
			"email": "E-mail",
			"www": "www",
			"ordered": "Objednáno",
			"bankApiConnect": "Napojení na API banky",
			"phoneNumber": "Telefonní číslo",
			"street": "Ulice",
			"street2": "Ulice 2",
			"city": "Město",
			"dueDate": "Splatnost",
			"afterDue": "Po splatnosti",
			"afterDueDays": "Po splatnosti [dny]",
			"amount": "Množství",
			"idPricelistBusinessUnitItem": "Položka ceníku",
			"zipCode": "PSČ",
			"countryCode": "Kód země",
			"countryName": "Název země",
			"currency": "Měna",
			"country": "Země",
			"documentCategory": "Kategorie dokumentu",
			"step": "Krok",
			"languageInvoice": "Jazyk faktury",
			"lastCreatedInvoice": "Poslední fakturace",
			"lastCreatedInvoiceReal": "Poslední faktura",
			"nextInvoice": "Příští faktura",
			"error": {
				"required": "Povinné",
				"notExistsInDb": "Neexistuje v databázi",
				"alreadyExists": "Již existuje",
				"notEmail": "Špatný formát e-mailu",
				"notPhoneNumber": "Špatný formát tel. čísla",
				"timeBadFormatShow": "Špatný formát času. Povolený formát: {format}",
				"dateBadFormatShow": "Špatný formát data. Povolený formát: {format}",
				"notUrl": "Špatný formát URL",
				"onlyNumeric": "Povolená jsou pouze čísla",
				"onlyAlphaNumeric": "Povolené jsou pouze písmena a čísla",
				"businessUnitRequired": "Není vybrán obchodní partner",
				"customerRequired": "Není vybrán zákazník",
				"priceMustBeHigherThenMinPrice": "Cena musí být vyšší nebo stejná než minimální cena",
				"rangeOnlyIn1And28": "Povolené rozmezí je 1 - 28.",
				"rangeOnlyIn1And12Months": "Povolené rozmezí je 1 - 12.",
				"saleCannotBeHigherThanPrice": "Sleva nesmí být vyšší než je cena",
				"dateNotBeBeforeX": "Datum nesmí být dříve než {date}",
				"repeatEndMustBeAfterStart": "Konec opakování musí být později než začátek",
				"minValueIsZero": "Minimální hodnota je \"0\"",
				"minValueIsOne": "Minimální hodnota je \"1\""
			}
		},
		"document": {
			"supportedFiles": "Podporované soubory",
			"chooseFile": "Vyberte soubor...",
			"chooseFileImage": "Vyberte obrázek...",
			"addNewImageTitle": "Nahrání obrázku",
			"maxFileSize": "Maximální velikost souboru",
			"error": {
				"unsupportedFileTypeError": "Nepodporovaný formát souboru",
				"maxFileSizeError": "Soubor nesmí být větší než",
				"emptyFileError": "Soubor je prázdný"
			}
		},
		"commonForm": {
			"submitTextPending": "Ukládám...",
			"submitText": "Uložit",
			"nextStep": "Další krok",
			"prevStep": "Předchozí krok",
			"formCancel": "Zrušit"
		},
		"commonFilter": {
			"recordsCount": "Počet záznamů",
			"searchInputPlaceholder": "Začněte psát",
			"submitTextPending": "Hledám...",
			"submitText": "Najít",
			"formReload": "Obnovit",
			"formCancel": "Zrušit"
		},
		"businessUnit": {
			"searchInputPlaceholder": "Vyhledat dle názvu, IČO",
			"addNewBusinessUnit": "Vložit nového obch. partnera",
			"newBusinessUnitTitle": "Nový obchodní partner",
			"editBusinessUnitTitle": "Editace obchodního partnera",
			"editInfo": "Editace adres, uživatelů a další nastavení naleznete ve spodní části detailu obchodního partnera.",
			"pricelist": {
				"noBusinessUnitFound": "Partner nebyl nalezen"
			},
			"settings": {
				"basic": "Základní údaje",
				"serialNumbers": "Číselné řady",
				"emailSending": "SMTP",
				"logo": "Loga",
				"invoicing": "Fakturace",
				"bankApiConnect": "Napojení na API banky",
				"prefix": "Prefix",
				"words": "Texty",
				"logoArea": {
					"addImage": "Nahrát obrázek",
					"changeImage": "Změnit obrázek",
					"removeImage": "Smazat obrázek",
					"removeImageQuestion": "Smazat {name}?",
					"logo": "Logo",
					"watermark": "Vodoznak",
					"watermarkResolution": "(1024 x 1024 px)",
					"imageNotSet": "Obrázek není nahrán"
				},
				"wordsArea": {
					"noContent": "Zatím není nastaven žádný text",
					"addLanguage": "Přidat jazyk",
					"languageNotSupported": "Tento jazyk není podporován",
					"deleteLanguage": "Odstranit jazyk \"{languageCode}\"?",
					"editText": "Upravit text",
					"placementExample": "Umístění textu na dokumentu",
					"submenu": {
						"infoCourtRegister": "Informace soud",
						"wordsOfferLegalNote": "Informace do nabídek",
						"wordsOfferEmail": "Email s nabídkou",
						"wordsInvoiceLegalNote": "Informace do faktur",
						"wordsInvoiceEmail": "Email s fakturou",
						"wordsInvoiceProformaLegalNote": "Informace do proforma faktur",
						"wordsInvoiceProformaEmail": "Email s proforma fakturou"
					}
				},
				"prefixNum": "Číselný prefix",
				"serialNumberSize": "Počet čísel",
				"nextSerialNumber": "Následující číslo",
				"serialNumberResult": "Následující označení",
				"pathGetData": "Cesta pro získání dat",
				"bank": "Banka",
				"urlAPI": "API URL",
				"token": "Token",
				"saveChangesInvoiceNumberQuestion": "Uložit změny?",
				"offer": {
					"title": "Nabídka",
					"changeSubmitQuestion": "Uložit změny pro řadu nabídek?"
				},
				"invoice": {
					"title": "Faktura",
					"changeSubmitQuestion": "Uložit změny pro řadu faktur?"
				},
				"invoiceProforma": {
					"title": "Proforma faktura",
					"changeSubmitQuestion": "Uložit změny pro řadu proforma faktur?"
				}
			}
		},
		"customer": {
			"searchInputPlaceholder": "Vyhledat dle názvu, IČO, obchodníka, kont. osoby",
			"addNewCustomer": "Vložit nového zákazníka",
			"newCustomerTitle": "Nový zákazník",
			"editCustomerTitle": "Editace zákazníka",
			"changeBusinessUnit": "Změnit",
			"addNewAlreadyExists": {
				"alert": "Zákazník s tímto IČO již existuje.",
				"question": "Pokračováním ho zařadíte do své databáze pod těmito údaji.",
				"continue": "Chci pokračovat",
				"storno": "Nechci pokračovat"
			},
			"addNewNoCompanyAlreadyExists": {
				"alert": "Zákazník s tímto emailem již existuje.",
				"question": "Pokračováním ho zařadíte do své databáze pod těmito údaji.",
				"continue": "Chci pokračovat",
				"storno": "Nechci pokračovat"
			},
			"errorCustomerWithCompanyNumberIsExisting": "Zákazník s tímto IČO již existuje!"
		},
		"businessCase": {
			"searchInputPlaceholder": "Vyhledat dle jména, emailu nebo telefonu",
			"addNewBusinessCase": "Nový obchodní případ",
			"newBusinessCaseTitle": "Nový obchodní případ"
		},
		"order": {
			"addNewOrderTitle": "Nová objednávka"
		},
		"invoice": {
			"addNewInvoiceTitle": "Nová faktura",
			"documentType": "Typ dokladu",
			"invoiceProforma": "Proforma",
			"invoice": "Faktura",
			"invoiceNotPaid": "Faktura (ozn. nezaplaceno)",
			"idPaymentMethod": "Způsob úhrady",
			"bankAccount": "Č. účtu",
			"bankCode": "Kód banky",
			"swiftBic": "SWIFT",
			"iban": "IBAN",
			"note": "Poznámka před položky",
			"footerNote": "Poznámka za položky",
			"customOrderNumber": "Číslo objednávky kupujícího",
			"sendEmailWithInvoice": "Odeslat email s fakturou",
			"creatingInvoiceWithNoFilledEmail": "Pokud nevyplníte email zákazníka, faktura dorazí na sběrný email nastavený na vaší firmě",
			"privateNote": "Soukromá poznámka (není na faktuře)",
			"due": "Splatnost [dny]",
			"dueDate": "Splatnost dne",
			"paymentMethod": {
				"bank": "Převodem na účet"
			}
		},
		"offer": {
			"itemsListCustomItem": "Vlastní položka",
			"addNewOffer": "Nová nabídka",
			"addNewOfferTitle": "Nová nabídka",
			"addItem": "Přidat položku",
			"addNewOfferItemTitle": "Přidat položku",
			"closeAndMakeInvoiceQuestion": "Uzavřít a vystavit fakturu?",
			"closeQuestion": "Uzavřít?",
			"close": "Uzavřít nabídku",
			"pdf": "PDF",
			"send": "Odeslat nabídku",
			"markAsPaid": "Označit jako zaplacené",
			"markAsPaidSuccess": "Označeno",
			"markAsPaidError": "Neoznačeno",
			"markAsPaidOutOfDateRange": "Datum zaplacení nesmí být pozdější než dnešní datum a zároveň nesmí být před datem vystavení faktury!",
			"markAsSent": "Nabídka odeslána",
			"ordered": "Objednáno",
			"sendEmailQuestion": "Odeslat e-mail?",
			"markAsSentQuestion": "Označit jako odeslané?",
			"uploadOrderFiles": "Nahrát objednávku",
			"copyOffer": "Kopírovat",
			"copyOfferQuestion": "Kopírovat nabídku?",
			"closeAndMakeInvoice": "Uzavřít a vystavit fakturu",
			"closed": {
				"success": "Uzavřeno",
				"error": "Neuzavřeno"
			},
			"error": {
				"offerIsNotFound": "",
				"offerIsNotEditable": "",
				"canNotMakeInvoice": "",
				"offerIsAlreadyOrdered": ""
			}
		},
		"dealer": {
			"searchInputPlaceholder": "Vyhledat dle jména, emailu nebo telefonu",
			"addNewDealer": "Vložit nového obchodníka",
			"newDealerTitle": "Nový obchodník"
		},
		"address": {
			"newAddressTitle": "Nová adresa",
			"editAddressTitle": "Editace adresy",
			"searchInputPlaceholder": "Vyhledat dle ulice, města",
			"addNewAddress": "Vložit novou adresu"
		},
		"person": {
			"newPersonTitle": "Nová osoba",
			"searchInputPlaceholder": "Vyhledat dle ulice, města",
			"addNewPerson": "Vložit novou osobu"
		},
		"user": {
			"editUserTitle": "Editace uživatele",
			"newUserTitle": "Nový uživatel",
			"searchInputPlaceholder": "Vyhledat dle jména, emailu nebo telefonu",
			"addNewUser": "Vložit nového uživatele",
			"deleteUser": "Smazat uživatele",
			"confirmDeleteUser": "Ano, smazat!",
			"dontDeleteUser": "Ponechat uživatele",
			"userWasNotDeleted": "Uživatele nebyl smazán!",
			"error": {
				"thisUserCannotBeAssign": "Tento e-mail nelze použít"
			}
		},
		"offerPage": {
			"subscriber": "Odběratel",
			"supplier": "Dodavatel",
			"note": {
				"add": "Přidat poznámku",
				"edit": "Editovat poznámku",
				"save": "Uložit poznámku",
				"storno": "Zrušit"
			},
			"offer": {
				"title": "Nabídka"
			},
			"order": {
				"title": "Objednávka"
			},
			"summary": {
				"totalPrice": "Celkem bez DPH",
				"totalPriceVat": "DPH",
				"totalPriceWithDPH": "Celkem s DPH",
				"totalPriceBeforeSale": "Celkem bez DPH před slevou",
				"totalPriceAfterSale": "Celkem bez DPH po slevě",
				"sale": "Sleva"
			}
		},
		"pricelist": {
			"country": {
				"addCurrency": "Přidat ceník v jiné měně"
			}
		},
		"priceItemsList": {
			"removeItem": "Odebrat položku?",
			"removeItemWithName": "Odebrat položku \"{name}?\"",
			"label": "Popis",
			"amount": "Počet",
			"unit": "MJ",
			"sale": "Sleva",
			"pricePerUnit": "Cena/MJ",
			"vatRate": "DPH %",
			"vatPrice": "DPH",
			"priceTotal": "Celkem bez DPH",
			"priceTotalWithDPH": "Celkem s DPH",
			"units": "Celkem s DPH"
		},
		"vatRates": {
			"vatRateStandard": "Základní sazba DPH",
			"vatRateReduced": "Snížená sazba DPH",
			"vatRateReduced2": "Snížená sazba DPH"
		},
		"units": {
			"long": {
				"pcs": "kus",
				"lm": "běžný metr",
				"hr": "hodina",
				"day": "den",
				"month": "měsíc",
				"year": "rok"
			},
			"short": {
				"pcs": "ks",
				"lm": "bm",
				"day": "den",
				"hr": "hod",
				"month": "měsíc",
				"year": "rok"
			}
		},
		"smtp": {
			"config": "SMTP konfigurace",
			"sender": "E-mail odesílatele",
			"host": "Host",
			"port": "PORT",
			"secure": "TLS zabezpečení",
			"authUser": "Uživatelské jméno",
			"authPass": "Heslo",
			"tlsRejectUnauthorized": "TLS odmítnout neautorizovaný",
			"test": "Otestovat SMTP spojení"
		},
		"bankAccounts": {
			"bankApiConnection": "Propojit s účtem",
			"editTitle": "Upravit bankovní účet",
			"addTitle": "Nový bankovní účet",
			"addBtn": "Přidat bankovní účet",
			"changeBankApi": "Změnit konfiguraci API",
			"bankApiIsActive": "API je nakonfigurované",
			"bankApiIsInactive": "API není nakonfigurováno",
			"runAccountJob": "Pustit automatické stahování dat z banky",
			"pauseAccountJob": "Zastavit automatické stahování dat z banky",
			"job": {
				"run": {
					"question": "Pustit automatické stahování dat z banky?",
					"confirm": "Pustit",
					"cancel": "Ponechat vypnuté",
					"success": "Automatické stahování dat zapnuto.",
					"error": "Automatické stahování dat nespuštěno!"
				},
				"pause": {
					"question": "Zastavit automatické stahování dat z banky?",
					"confirm": "Zastavit",
					"cancel": "Ponechat zapnuté",
					"success": "Automatické stahování dat vypnuto.",
					"error": "Automatické stahování dat nevypnuto!"
				}
			},
			"delete": {
				"title": "Odstranit bankovní účet?",
				"confirm": "Odstranit účet",
				"cancel": "Ponechat účet",
				"success": "Bankovní účet byl odstraněn",
				"error": "Nepodařilo se odstranit bankovní účet"
			}
		},
		"repeatInvoice": {
			"noEnd": "Bez konce",
			"detailTitle": "Pravidelná faktura",
			"newTitle": "Nová pravidelná faktura",
			"newBtn": "Vytvořit pravidelnou fakturu",
			"customAddress": "- Jinou adresu -",
			"customContactPerson": "- Jiný kontakt -",
			"dateStart": "Začátek opakování",
			"dateEnd": "Konec opakování",
			"due": "Splatnost [dny]",
			"proforma": "Vystavit jako proforma fakturu",
			"executeInDayOfMonth": "Posílat v den v měsíci",
			"bankAccount": "Bankovní účet",
			"repeatIntervalInMonth": "Opakovat každých \"x\" měsíců",
			"customOrderNumber": "Číslo objednávky zákazníka",
			"email": "E-mail pro zasílání",
			"transferredTaxLiability": "Přenesená daňová povinnost",
			"noteAfterSummary": "Poznámka (na konci faktury)",
			"summary": "Souhrn",
			"addItem": "Přidat položku",
			"step1": "Dodavatel",
			"step2": "Odběratel",
			"step3": "Nastavení",
			"step4": "Položky",
			"step5": "Souhrn",
			"noItems": "Nejsou žádné položky faktury. Vytvořte alespoň jednu",
			"executeInDayOfMonthWillExecuteInNextMonth": "Nastavený den zasílání tento měsíc již byl. První faktura se vygeneruje v následující intervalu."
		},
		"repeatInvoiceDetail": {
			"noInvoicesYet": "Zatím nebyla vystavena žádná faktura",
			"views": {
				"detail": "Detail",
				"items": "Fakturované položky",
				"invoices": "Vystavené faktury"
			},
			"edit": {
				"button": "Upravit konfiguraci"
			},
			"delete": {
				"button": "Smazat konfiguraci",
				"buttonNoDelete": "Ponechat konfiguraci",
				"question": "Smazat konfiguraci?",
				"questionSubtext": "Smazat tuto konfiguraci pro pravidelnou fakturu?",
				"success": "Smazáno",
				"error": "Nesmazáno"
			},
			"deleteLastCreatedInvoiceInfo": {
				"button": "Smazat info o faktuře",
				"buttonNoDelete": "Ponechat informaci",
				"question": "Smazat informaci o poslední vytvořené faktuře?",
				"success": "Smazáno",
				"error": "Nesmazáno"
			}
		},
		"sendPayObject": {
			"modalSendTitle": "Odeslat",
			"modalPayTitle": "Zaplatit",
			"or": "nebo",
			"markAsSent": {
				"toggleButtonOnly": "Pouze označit \"odesláno\"",
				"toggleButton": "Označit \"odesláno\"",
				"title": "Označit \"odesláno\"?",
				"confirm": "Ano",
				"success": "Označeno",
				"error": "Neoznačeno"
			},
			"markAsPaid": {
				"toggleButtonOnly": "Pouze označit \"zaplaceno\"",
				"toggleButton": "Označit \"zaplaceno\"",
				"title": "Označit \"zaplaceno\"?",
				"confirm": "Ano",
				"success": "Označeno",
				"error": "Neoznačeno"
			},
			"send": {
				"title": "Odeslat",
				"submit": "Odeslat",
				"success": "Odesláno",
				"error": "Neodesláno"
			}
		}
	},
	"Currency": {
		"CZK": "Kč",
		"EUR": "€"
	},
};

export default cs;

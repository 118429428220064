import clearBrowserStorage from '../../utils/clearBrowserStorage';

export const AUTH_PENDING = 'auth/AUTH_PENDING';
export const AUTH_LOADED = 'auth/AUTH_LOADED';
export const AUTH_ERROR = 'auth/AUTH_ERROR';
export const LOGOUT = 'LOGOUT';

const initialState = {
	authorized: false,
	pending: false,
	loaded: false,
	error: null
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case AUTH_PENDING:
			return { ...state, authorized: false, pending: true, loaded: false, error: null };

		case AUTH_LOADED:
			return { ...state, authorized: action.authorized, pending: false, loaded: false, error: action.error };

		case AUTH_ERROR:
			return { ...state, authorized: false, pending: false, loaded: false, error: action.error };

		case LOGOUT:
			clearBrowserStorage();
			return initialState;

		default:
			return state;
	}
}
